<template>
    <div class="w-100">
    <map-area-selector
        ref="areaSelector"
        :draw-all="drawAll"
        :contract-selected="selectedContractIds && selectedContractIds.length > 0"
        :contracts="selectedContractIds"
        @areaLabelsChanged="updateAreaLabelsVisibility"
        @areaCollectionChanged="updateAreaCollection"
        @areaSelectionChanged="updateAreaTypeSelection"
    />
    <div
        v-if="loading"
        id="loader"
        class="spinner"
    />
    </div>
</template>

<script>

import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from "../mixins/MapMixin";
import MapAreaSelector from "@/components/area/MapAreaSelector.vue";

export default {
    name: 'MapAreas',
    components: {MapAreaSelector},
    mixins: [restApi, mapHelper],
    props: {
        company: {
            type: Number
        },
        map: {
            type: Object,
            default: null
        },
        showLabels: {
            type: Boolean,
            default: false
        },
        drawAll: {
            type: Boolean,
            default: false
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data: function () {
        return {
            loading: false,
            results: [],
            areaNames: [],
            selectedAreaTypes: [],
            selectedAreas: [],
            areaCollection: null,
        }
    },

    mounted() {
        if (this.drawAll) {
            this.$refs.areaSelector.fetchAreaTypes()
        }
    },

    watch: {
        selectedAreaTypes: function () {
            if(this.results && this.map) {
                this.drawAreas(this.results)
            }
        },
        selectedAreas: function (areas) {
            if(areas && this.map) {
                this.results = areas
                this.drawAreas(this.results)
            }
        },
        map() {
            this.$nextTick(function () {
                if (this.results && this.map) {
                    this.drawAreas(this.results)
                }
            })
        },
        showLabels: function () {
            if(this.results && this.map) {
                this.drawAreas(this.results)
            }
        },
        areaCollection: function () {
            if (this.selectedContractIds && this.selectedContractIds.length === 0) {
                this.clearAreas()
                this.$refs.areaSelector.fetchAreasBy()
            }
            else {
                this.clearAreas()
                this.$refs.areaSelector.fetchAreaTypes()
            }
        }
    },

    beforeDestroy() {
        this.hideAreas()
    },

    methods: {
        updateAreaTypeSelection: function (types, areas) {
            this.selectedAreaTypes = types
            this.selectedAreas = areas
        },

        updateAreaLabelsVisibility: function (visible) {
            this.$emit('updateAreaLabelsVisibility', visible)
        },

        updateAreaCollection: function (collection) {
            this.areaCollection = collection
        },



        drawAreas: function (areas) {
            this.hideAreas()
            if (areas.length) {
                    areas.forEach(item => {
                        if (this.selectedAreaTypes && this.selectedAreaTypes.includes(item.type.id) || this.drawAll) {
                            let rings = item.geometry.rings
                            if (item.color) {
                                this.map.drawPolygon(item.id, rings, this.AREA, item.color, item.color, 0.4)
                            } else {
                                this.map.drawPolygon(item.id, rings)
                            }

                            if (this.showLabels) {
                                this.map.addAreaLabel(item.id, item.name, rings[0][0][1], rings[0][0][0])
                            }
                        }
                    })
                this.map.attachEventListenersToAreas()
                this.editingAreaEventFlag = false
                this.newPolygonId = -1
            }

        },

        hideAreas: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.AREA)
                this.map.hideAreaLabels(this.AREA)
            }
        },

        clearAreas: function () {
            this.hideAreas()
            this.results = []
        },

    }
}
</script>
