<template>
    <div v-if="results && results.length > 0" class="nopads">
        <b-row>
            <b-col class="nopads report-header" cols="10">
                <span class="section-title" style="display: block">{{ $t('material_reports.title') }}</span>
                <b-row class="nopads">
                    <b-col class="nopads">
                        <div>{{ results ? results.length : 0 }} {{ $t('danger_report.reports') }}</div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="nopads">
                <font-awesome-icon
                    icon="arrow-down"
                    class="pointer right no-focus arrow-icon dont-print"
                    v-bind:class="{rotated : materialsVisible}"
                    v-b-toggle.materialsDetails
                />
            </b-col>
        </b-row>
        <b-collapse
            id="materialsDetails"
            v-model="materialsVisible"
            class="mt-2 mb-2 item-details show-print"
        >
            <!-- Summary -->
            <div class="col-12 text-center">
                <contract-material-summary
                    :results=results
                    @emitShowAddMaterialReport="showAddMaterialReportModal"
                    @emitDownloadMaterialReportExcel="downloadMaterialReportExcel"
                />
            </div>

            <transition name="fade">
                <div class="list-content result-table pointer">
                    <b-table
                        show-empty
                        small
                        striped
                        hover
                        :items="results"
                        :fields="fields"
                        @row-clicked="editMaterialReportItem"
                    >
                        <template v-slot:cell(date)="row">
                            {{ getShortDateString(row.value) }}
                        </template>
                    </b-table>
                </div>
            </transition>
        </b-collapse>
        <hr class="report-divider nopads"/>
        <div
            v-if="materialReport !== null"
            class="details-container"
        >
            <div
                class="details dynamic_width">
                <contract-material-report-editor
                    :material-report="materialReport"
                    :search-params="searchParams"
                    @emitAddMaterialReport="submitMaterialReport"
                    @close="closeEditor"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {downloadHelper} from "@/components/mixins/DownloadMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import {materialReportHelper} from "@/components/mixins/MaterialReportMixin";
import ContractMaterialReportEditor from "@/components/contractreport/ContractMaterialReportEditor.vue";
import ContractMaterialSummary from "@/components/contractreport/ContractMaterialSummary.vue";
import {EventBus} from "@/event-bus";

export default {
    name: "ContractMaterialReports",
    components: {ContractMaterialSummary, ContractMaterialReportEditor},
    mixins: [timeUtils, restApi, materialReportHelper, downloadHelper],
    props: {
        searchParams: {
            type: Object,
            default() {
                return null
            }
        },
        contract: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data: function () {
        return {
            loading: false,
            results: [],
            materialsVisible: false,
            materialReport: null,
            fields: [
                {
                    date: {
                        label: this.$t('daily_summary.date'),
                        sortable: true
                    }
                },
                {
                    reporter_name: {
                        label: this.$t('material_reports.reporter'),
                        sortable: true
                    }
                },
                {
                    customer_name: {
                        label: this.$t('contracts.customer'),
                        sortable: true
                    }
                },
                {
                    contract_name: {
                        label: this.$t('orders.contract'),
                        sortable: true
                    }
                },
                {
                    material: {
                        label: this.$t('material_reports.material'),
                        sortable: true
                    }
                },
                {
                    amount: {
                        label: this.$t('material_reports.amount'),
                        sortable: true
                    }
                },
                {
                    unit: {
                        label: this.$t('material_reports.unit'),
                        sortable: true
                    }
                },
                {
                    info: {
                        label: this.$t('material_reports.info'),
                        sortable: true
                    }
                }
            ]
        }
    },
    mounted() {
        this.fetchMaterialReports()
    },
    methods: {
        fetchMaterialReports: function () {
            this.results = []
            if (this.searchParams) {
                let mappedParams = {
                    contract: this.searchParams.contract,
                    from: this.searchParams.dateFrom ? this.getDateIsoString(new Date(this.searchParams.dateFrom)) : null,
                    to: this.searchParams.dateTo ? this.getDateIsoString(new Date(this.searchParams.dateTo)) : null,
                }
                this.$emit('loading', true)
                this.restFetchParams(this.materialReportUrl, mappedParams, this.handleResponse)
            }
        },
        handleResponse: function (response) {
            if (response) {
                this.results = response.data
            }
            this.$emit('loading', false)
        },
        editMaterialReportItem: function (item) {
            this.materialReport = {
                ...item,
                contract: this.contract && this.contract.id && this.contract.id > 0 ? {
                    id: this.contract.id,
                    name: this.contract.name
                } : null,
                reporting_date: item.date ? item.date : null,
            }
        },
        closeEditor() {
            this.materialReport = null
        },
        submitMaterialReport(item) {
            let jsonMaterialReport = this.setDefaultValues(item)
            if(jsonMaterialReport !== undefined) {
                this.loading = true
                if (jsonMaterialReport.id < 0) {
                    this.restAdd(this.simpleMaterialReportUrl, jsonMaterialReport, this.handleMaterialReportResponse, this.fail)
                } else {
                    this.restUpdate(this.simpleMaterialReportUrl, jsonMaterialReport, this.handleMaterialReportResponse, this.fail)
                }
            }
        },
        setDefaultValues: function (value) {
            let materialReport = JSON.parse(JSON.stringify(value))
            if (materialReport.material === '') {
                EventBus.$emit('show-alert', this.$t('material_reports.invalid_material'))
                return undefined
            }
            if(materialReport.reporting_date) {
                materialReport.reporting_date = this.timestampToLocalTime(materialReport.reporting_date)
            }
            if (materialReport.unit === '') {
                EventBus.$emit('show-alert', this.$t('pricing_model_conversion.invalid_unit'))
                return undefined
            }

            if (materialReport.info === null || materialReport.info === '') {
                EventBus.$emit('show-alert', this.$t('material_reports.info_missing'))
                return undefined
            }

            materialReport.consumption_records = [{
                id: materialReport.record_id && materialReport.record_id,
                material: materialReport.material,
                amount: materialReport.amount,
                unit: materialReport.unit,
                info: materialReport.info,
            }]
            return materialReport
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        downloadMaterialReportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.materialReportExcelUrl,
                {...this.getCommonReportParams(this.searchParams),
                from: this.searchParams.dateFrom ? this.getDateIsoString(new Date(this.searchParams.dateFrom)) : null,
                to: this.searchParams.dateTo ? this.getDateIsoString(new Date(this.searchParams.dateTo)) : null},
                this.fileDownloadSuccess,
                this.fileDownloadFail)
        },
        fileDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('material_reports.file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
        handleMaterialReportResponse: function (response) {
            if (response) {
                this.loading = false
                this.materialReport = null
                this.fetchMaterialReports()
                this.materialsVisible = true
            }
        },
        showAddMaterialReportModal() {
            this.materialReport = {
                ...this.getSimpleMaterialReport(),
                contract: this.contract && this.contract.id && this.contract.id > 0 ? {
                    id: this.contract.id,
                    name: this.contract.name
                } : null,
            }
        }
    }
}
</script>

<style scoped>

</style>
