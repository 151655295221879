<template>
    <div class="col-sm-12 p-2">
        <transition name="fade">
            <div
                v-if="results && results.length"
                id="accordion"
                class="col-sm-12 panel-group data-container p-2"
            >
                <div
                    v-for="(result) in results"
                    :key="result.company_id"
                    v-b-toggle="'accordion-'+result.company_id"
                    class="data-list-item p-2"
                >
                        <div
                            class="col-sm-12 item-header"
                            @click.stop="startUserFetch(result.company_id)"
                        >
                            <div class="item-text-cont">
                              <span class="item-title green">
                                {{ result.company_name }}
                               </span>
                                <table class="table table-sm">
                                    <tbody>
                                        <tr>
                                            <th>{{ $t('license_report.superusers') }}</th>
                                            <th>{{ $t('license_report.billed_observers')}}</th>
                                            <th>{{ $t('license_report.usage_observers') }}</th>
                                            <th>{{ $t('license_report.billed_workers') }}</th>
                                            <th>{{ $t('license_report.usage_workers') }}</th>
                                        </tr>
                                        <tr>
                                            <td>{{ result.company_super_users }}</td>
                                            <td>{{ result.company_observers }}</td>
                                            <td>{{ result.supervisor_usage}}</td>
                                            <td>{{ result.company_workers }}</td>
                                            <td>{{ result.worker_usage}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <b-collapse
                            :id="'accordion-'+result.company_id"
                            class="mt-2 item-details"
                        >
                            <div
                                class="row nopads"
                                v-b-toggle="'accordion-'+result.company_id"
                            >
                                <div  v-if="users.length > 0" class="col-sm-12 item-detail-container">
                                    <span> {{ $t('license_report.license_holder_users') }} </span>
                                        <license-admin-report-table

                                            :users=users
                                            @details="showUserDetails"
                                        />
                                </div>
                                <div v-else class="col-sm-12 item-detail-container">
                                    <b-button
                                        v-if="expiredRightsUsers.length === 0 && !expiredRightsFetched"
                                        @click="fetchExpiredRightsUsers(result.company_id)"
                                        size="sm"
                                        variant="primary">
                                        {{ $t('license_report.search_expired_license_rights') }}
                                    </b-button>
                                    <div v-else>
                                        <span> {{ $t('license_report.expired_license_rights') }} </span>
                                        <license-admin-report-table
                                            :users=expiredRightsUsers
                                            @details="showUserDetails"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 item-detail-container">
                                    <div class="item-detail-title">
                                        {{ $t('companies.name') }}
                                    </div>
                                    <div class="item-detail-text">
                                      <span>
                                        {{ result.company_name }}
                                      </span>
                                    </div>

                                    <div class="item-detail-title">
                                        {{ $t('companies.business_id') }}
                                    </div>
                                    <div class="item-detail-text">
                                      <span v-if="result.company_business_id">
                                        {{ result.company_business_id }}
                                      </span>
                                       <span v-else>
                                        -
                                      </span>
                                    </div>
                                </div>
                            </div>
                        </b-collapse>
                </div>
            </div>
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />

    </div>
</template>

<script>
import {userHelper} from '../mixins/UserMixin'
import {restApi} from '../mixins/RestApiMixin'
import LicenseAdminReportTable from './LicenseAdminReportTable'


export default {
    name: 'LicenseSummaryTable',
    mixins: [userHelper, restApi],
    components: { LicenseAdminReportTable},
    props: {
        dateFrom: {
            type: Number
        },
        dateTo: {
            type: Number
        },
        results: {
            type: Array,
            default() {
                return []
            }
        },
        allusers: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    },
    data() {
        return {
            users: [],
            lastinfo: '',
            userDataLoaded: false,
            loading: false,
            accordionOpen: false,
            fetched: false,
            fetchCallbacked: false,
            openAccordions: [],
            fetchedData: null,
            expiredRightsUsers: [],
            expiredRightsFetched: false,
        }
    },
    methods: {
        startUserFetch: function (companyId) {
            if(!this.loading) {22
                if (this.openAccordions.length === 0) {
                    this.loading = true
                    this.fetchUsers(companyId)
                } else if (this.openAccordions.indexOf(parseInt(companyId)) === -1) {
                    this.$root.$emit('bv::toggle::collapse', 'accordion-'+this.openAccordions.pop())
                    this.loading = true
                    this.fetchUsers(companyId)
                } else if(this.openAccordions.indexOf(parseInt(companyId)) !== -1) {
                    this.$root.$emit('bv::toggle::collapse', 'accordion-'+this.openAccordions.pop())
                }
            }
        },
        fetchUsers: function (companyId) {
            this.fetched = true
            let params = {}
            params.company = companyId
            params.dateStart =this.dateFrom
            params.dateEnd = this.dateTo
            this.restFetchParams(this.licenseUsersAdminURL, params, this.handleUsers)

        },
        handleUsers: function (response) {
            const companyId = response.data[0].companyId
            this.$root.$emit('bv::toggle::collapse', 'accordion-'+companyId)
            this.openAccordions.push(parseInt(companyId))
            this.expiredRightsUsers = []
            this.expiredRightsFetched = false
            this.fetchCallbacked = true
            this.users = response.data[1]
            this.userDataLoaded = true
            this.loading = false
        },
        fetchExpiredRightsUsers: function (companyId) {
            this.fetched = true
            let params = {}
            params.company = companyId
            this.restFetchParams(this.expiredLicenseUsersAdminURL, params, this.handleExpiredRightsUsers)

        },
        handleExpiredRightsUsers: function (response) {
            this.fetchCallbacked = true
            this.expiredRightsUsers = response.data[1]
            this.expiredRightsFetched = true
            this.loading = false
        },
        showUserDetails: function (row){
            this.lastinfo = row
        }

    }
}
</script>
<style scoped>
.green {
    color: #7FC000;
}
</style>
