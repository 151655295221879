<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-row class="nopads">
                <div class="col-sm-6">
                    <span class="search-title">
                        {{ $t('delivery_note.date') }} - {{ $t('trip_list.road_sec_from') }}
                    </span>
                    <datetime
                        v-model="fromTime"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        class="routa-input"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>
                <div class="col-sm-6">
                    <span class="search-title">
                        {{ $t('delivery_note.date') }} - {{ $t('trip_list.road_sec_to') }}</span>
                    <datetime
                        v-model="toTime"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm"
                        class="routa-input"
                        :placeholder="$t('observations_videos.search_input_format')"
                    />
                </div>
            </b-row>
            <b-row class="nopads pt-1">
                <div class="col-sm-6 nopads pl-3 pr-2">
                    <span class="search-title">{{ $t('orders.contract') }}</span>
                    <open-contract-filter
                        :to="toTime"
                        :from="fromTime"
                        :show-company="false"
                        :show-title="false"
                        :showOnlyMyContractsOption="true"
                        @loading="loading++"
                        @loadingCompleted="loading--"
                        @contractSelected="setContract"
                    />
                </div>
                <div class="col-sm-6 pt-1">
                    <span class="search-title">{{ $t('trip_list.vehicle') }}</span>
                    <multiselect
                        v-model="vehicle"
                        class="multiselect_routa"
                        :placeholder="$t('trip_list.select_vehicle')"
                        :deselect-label="$t('common.remove_selection')"
                        :select-label="$t('common.select')"
                        :close-on-select="true"
                        label="name"
                        track-by="name"
                        :searchable="true"
                        :internal-search="true"
                        :selected-label="$t('common.selected')"
                        :options="vehicles"
                    >
                        <template
                            slot="singleLabel"
                            slot-scope="props"
                        >
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                          </span>
                        </template>
                        <template
                            slot="option"
                            slot-scope="props"
                        >
                            <div class="option__desc">
                              <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('common.not_found') }}</span>
                        <span slot="noOptions">{{ $t('common.no_results') }}</span>
                    </multiselect>
                </div>
            </b-row>
            <div class="col-sm-12 button-container">
                <b-button
                    type="submit"
                    variant="primary"
                    class="result-button"
                    size="sm"
                    v-on:click.stop="onSubmit"
                >
                    {{ $t('ins_report.search') }}
                </b-button>
                <b-button
                    type="reset"
                    variant="danger"
                    class="result-button"
                    size="sm"
                    v-on:click.stop="onReset"
                >
                    {{ $t('ins_report.clear') }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from '../../mixins/TimeUtils'
import OpenContractFilter from "@/components/contract/OpenContractFilter";

export default {
    name: 'SpreaderDataSearch',
    components: {OpenContractFilter},
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            loading: false,
            contracts: [],
            vehicles: [],
            fromTime: null,
            toTime: null,
            contract: null,
            vehicle: null
        }
    },
    mounted: function () {
        this.fromTime = this.getMonthStart()
        this.toTime = this.timestampToISOString(new Date().getTime())
        this.fetchVehicles()
    },
    methods: {
        fetchVehicles: function () {
            this.loading++
            this.vehicles = []
            this.restFetchParams(this.vehicleUrl, {only_spreader_vehicles: true}, this.handleVehicles)
        },
        handleVehicles: function (response) {
            this.loading--
            if (response && response.data) {
                this.vehicles = response.data.map(function (item) {
                    return {
                        value: item.id,
                        name: item.make + ' ' + item.vehicle_model + ' - ' + item.license_plate
                    }
                })
            }
        },
        setContract(contract) {
            this.contract = contract
        },
        onSubmit: function () {
            var params = {}
            if (this.vehicle) {
                params.vehicle = this.vehicle.value
            }
            if (this.contract) {
                params.contract = this.contract.id
            }
            params.from = this.fromTime
            params.to = this.toTime
            this.$emit('search', params)
        },

        onReset: function () {
            this.vehicles = null
            this.toDate = null
        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
