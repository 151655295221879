<template>
    <div ref="container" class="content-container">
        <b-navbar
            variant="faded"
            @click="closeAndUpdate"
        >
            <div
                v-if="!editMode && !showWizard"
                class="header"
            >
                <span class="title">{{ $t('contracts.title') }}</span>
            </div>
            <div
                v-else
                class="header"
                @click="closeAndUpdate"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer"> {{ $t('common.back') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition-group name="fade">
                <div key="search-contract-list">
                    <keep-alive>
                        <contract-search
                            v-if="!editMode && !showWizard"
                            @search="fetchContracts"
                        />
                    </keep-alive>
                    <div v-if="!editMode && !showWizard" class="col-sm-12">
                        <div
                            class="data-list-item add"
                            @click.stop="addContract"
                        >
                            <div class="item-img-cont add-title">
                                <i class="fa fa-plus"/>
                            </div>
                            <div class="item-text-cont">
                                <span class="item-title-add">{{ $t('common.add_new') }}</span>
                                <font-awesome-icon
                                    icon="file-contract"
                                    class="card-body-icon"
                                />
                            </div>
                        </div>
                        <div
                            class="data-list-item import hidden"
                            @click.stop="showContractImporter"
                        >
                            <div class="item-img-cont add-title">
                                <i class="fa fa-plus"/>
                            </div>
                            <span class="item-title-add">{{ $t('common.import_from_file') }}</span>
                            <font-awesome-icon
                                icon="file-contract"
                                class="card-body-icon"
                            />
                        </div>
                    </div>
                    <keep-alive>
                        <contract-list
                            v-if="!editMode && !showWizard"
                            :results="results"
                            @edit="editContract"
                        />
                    </keep-alive>
                </div>
            </transition-group>
            <transition name="fade">
                <contract-wizard
                    v-if="showWizard"
                    :user="user"
                    @customerSet="setCustomer"
                    @contractorSet="setContractor"
                />
            </transition>
            <transition name="fade">
                <contract-details
                    v-if="editMode"
                    v-model="contract"
                    :user="user"
                    :is-observer="isObserver"
                    :activeOrderEditor="activeOrderEditor"
                    @orderActiveChange="checkActiveOrder"
                    @showList="showList"
                    @closeAndUpdate="closeAndUpdate"
                    @scrollTop="scrollTop"
                />
            </transition>
            <b-modal
                ref="contractsFromFileModal"
                centered
                hide-header-close
                hide-footer
                :title="$t('contracts.import_from_file')">
                <contract-importer
                    @closeAndUpdate="contractsImported"
                    @close="hideContractImporter"
                />
            </b-modal>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {restApi} from '../mixins/RestApiMixin'
import ContractWizard from './ContractWizard'
import ContractList from './ContractList'
import ContractSearch from './ContractSearch'
import ContractImporter from "./ContractImporter";
import ContractDetails from "@/components/contract/ContractDetails";
import {EventBus} from "@/event-bus";

export default {
    name: 'Contracts',
    components: {ContractDetails, ContractImporter, ContractList, ContractWizard, ContractSearch},
    mixins: [workManagementHelper, restApi],
    props: {
        user: {
            type: Object,
            default: null
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        preSelectedContract: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            maxContracts: 150,
            results: [],
            contract: null,
            editMode: false,
            showWizard: false,
            loading: false,
            lastSearchedParams: null,
            customerName: null,
            contractorName: null,
            activeOrderEditor: false,
            scrollPosition: 0
        }
    },
    mounted: function () {
        this.lastSearchedParams = {open: 1}
        if (this.preSelectedContract) {
            this.editContract(this.preSelectedContract)
        }
    },
    methods: {
        checkActiveOrder: function(active){
            this.activeOrderEditor = active
        },
        fetchContracts: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.restFetchParams(this.contractUrl, this.lastSearchedParams, this.handleResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
                // Inform user if max number of contracts were returned
                if (this.results.length === this.maxContracts) {
                    EventBus.$emit('show-alert', this.$t('contracts.external.max_returned'), 'warning')
                }
            }
        },

        editContract: function (contract) {
            this.contract = this.jsonToItem(contract);
            this.editMode = true
        },

        addContract: function () {
            this.contract = this.newContract()
            this.showWizard = true
        },

        showContractImporter() {
            this.$refs.contractsFromFileModal.show()
        },

        hideContractImporter() {
            this.$refs.contractsFromFileModal.hide()
        },

        contractsImported() {
            this.hideContractImporter()
            this.fetchContracts(this.lastSearchedParams)
        },

        showList: function () {
            this.editMode = false
            this.showWizard = false
        },

        closeAndUpdate: function () {
            if(this.activeOrderEditor){
                this.editMode = true
                this.activeOrderEditor = false
                this.$nextTick(function () {
                    this.$refs.container.scroll({
                        top: this.scrollPosition,
                        behavior: 'smooth',
                    });
                })
            }else{
                this.fetchContracts(this.lastSearchedParams)
                this.showList()
            }
        },

        setCustomer: function (customer) {
            this.contract.customer = customer
            this.customerName = customer.name
            this.editMode = (this.contract.customer && this.contract.customer.id && this.contract.contractor && this.contract.contractor.id)
            this.showWizard = !this.editMode
        },

        setContractor: function (contractor) {
            this.contract.contractor = contractor
            this.contractorName = contractor.name
            this.editMode = (this.contract.customer && this.contract.customer.id && this.contract.contractor && this.contract.contractor.id)
            this.showWizard = !this.editMode
        },

        scrollTop: function () {
            this.scrollPosition = this.$refs.container.scrollTop
            this.$nextTick(function () {
                this.$refs.container.scrollTop = 0
            })
        }
    }
}
</script>
