<template>
        <contract-material-summary-values
            :material-combined="materialCombined"
            @emitShowAddMaterialReport="$emit('emitShowAddMaterialReport')"
            @emitDownloadMaterialReportExcel="$emit('emitDownloadMaterialReportExcel')"
        />
</template>

<script>
import ContractMaterialSummaryValues from "@/components/contractreport/ContractMaterialSummaryValues.vue";

export default {
    name: "ContractMaterialSummary",
    components: {ContractMaterialSummaryValues},
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data: function () {
        return {
            materialCombined: [],
        }
    },

    created() {
        this.handleResponse()
    },

    watch: {
        results: function () {
            this.handleResponse()
        }
    },
    methods: {
        calculateMaterialSummary() {
            this.materialCombined = this.sumMaterials(this.results)
        },
        sumMaterials(results) {
            const summedMaterials = {};

            results.forEach(item => {
                const { material, amount, unit } = item;

                // Ensure the material exists in the summedMaterials object
                if (!summedMaterials[material]) {
                    summedMaterials[material] = { amount: 0, unit };
                }

                // Convert units if necessary
                if (unit === 't' && summedMaterials[material].unit === 'kg') {
                    summedMaterials[material].amount += amount * 1000;
                } else if (unit === 'kg' && summedMaterials[material].unit === 't') {
                    summedMaterials[material].amount += amount / 1000;
                } else if (unit === summedMaterials[material].unit) {
                    summedMaterials[material].amount += amount;
                }
            });

            return Object.keys(summedMaterials).map(key => ({
                material: key,
                amount: summedMaterials[key].amount,
                unit: summedMaterials[key].unit
            }));
        },

        handleResponse: function () {
            this.materialCombined = []
            this.calculateMaterialSummary()
        },
    }
}
</script>

