<template>
    <div class="col-sm-12 pb-3" style="min-height: 30em">
        <h4 class="form-header">{{ $t('contracts.external.reports')}}</h4>
        <b-row class="nopads">
            <!-- Date selector for report -->
            <div class="col-sm-6 nopads pr-1 pl-1">
                <span class="span-title">
                    {{ $t('contracts.external.date') }}
                </span>
                <b-form-input
                    id="reportDate"
                    type="date"
                    :value="timestampToDateInput(reportDate)"
                    size="sm"
                    :max="today"
                    @change="updateReportDate"
                />
            </div>
            <div class="col-sm-6 nopads pr-1 pl-1">
                <span class="span-title">
                    {{ $t('contracts.external.report') }}
                </span>
                <b-form-select
                    id="report"
                    v-model="selectedReport"
                    size="sm"
                    :options="reportOptions"
                />
            </div>
        </b-row>
        <contract-daily-trips
            v-if="selectedReport === REPORT_EQUIPMENT_INSPECTION || selectedReport === REPORT_ROAD_SAFETY || selectedReport === REPORT_ROAD_INSPECTION || selectedReport === REPORT_WORK_ROUTE || selectedReport === REPORT_MATERIAL"
            ref="trips"
            :contract="contract"
            :selectedReportType="selectedReport"
            :date="reportDate.getTime()"
            :with-geom="selectedReport === REPORT_ROAD_SAFETY || selectedReport === REPORT_ROAD_INSPECTION || selectedReport === REPORT_MATERIAL"
            :button-text="tripButtonText"
            @createReports="createTripBasedReport"/>
        <contract-work-diary
            v-if="selectedReport === REPORT_WORK_DIARY"
            :contract="contract"
            :date="reportDate"
            @submitReport="submitDiary"/>
        <contract-road-list-preview
            v-if="roads.length > 0"
            :roads="roads"
        />
        <contract-road-safety
            v-if="roadSafetyResult && (selectedReport === REPORT_ROAD_SAFETY || selectedReport === REPORT_ROAD_INSPECTION)"
            :result="roadSafetyResult"
            :report="selectedReport"
        />
        <contract-equipment-filter
            class="pb-4"
            v-if="selectedReport === REPORT_EQUIPMENT_INSPECTION && roads.length > 0"
            @equipmentSelected="equipmentsSelected"/>
        <div v-if="reportReady" class="col-sm-12 nopads pt-2 pb-2 text-right">
            <b-button
                variant="success"
                class="result-button"
                v-on:click.stop="submitReport"
                :disabled="loading"
            >
                {{ $t('contracts.external.submit_report') }}
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {workManagementHelper} from '../../mixins/WorkManagementMixin'
import {userHelper} from "@/components/mixins/UserMixin";
import ContractDailyTrips from "@/components/contract/external/ContractDailyTrips";
import ContractWorkDiary from "@/components/contract/external/ContractWorkDiary";
import ContractRoadListPreview from "@/components/contract/external/ContractRoadListPreview";
import ContractEquipmentFilter from "@/components/contract/external/ContractEquipmentFilter";
import {EventBus} from "@/event-bus";
import ContractRoadSafety from "@/components/contract/external/ContractRoadSafety";

export default {
    name: 'ContractElyReports',
    components: {
        ContractRoadSafety,
        ContractEquipmentFilter, ContractRoadListPreview, ContractWorkDiary, ContractDailyTrips},
    mixins: [workManagementHelper, restApi, userHelper],
    props: {
        contract: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            REPORT_ROAD_SAFETY: 1,
            REPORT_WORK_DIARY: 2,
            REPORT_EQUIPMENT_INSPECTION: 3,
            REPORT_ROAD_INSPECTION: 4,
            REPORT_MATERIAL: 5,
            REPORT_WEATHER: 6,
            REPORT_WORK_ROUTE: 7,
            loading: false,
            reportDate: null,
            selectedReport: null,
            reportOptions: [],
            roads: [],
            equipmentTypes: [],
            roadSafetyResult: null
        }
    },
    computed: {
        tripButtonText: function() {
            switch (this.selectedReport) {
                case this.REPORT_WORK_ROUTE: return this.$t('contracts.external.submit_report')
                case this.REPORT_MATERIAL: return this.$t('common.continue')
            }
            return this.$t('contracts.external.create_report')
        },
        reportReady: function () {
           if (this.selectedReport === this.REPORT_EQUIPMENT_INSPECTION && this.roads.length > 0 && this.equipmentTypes.length > 0) {
               return true
           }
           if (this.roadSafetyResult && (this.selectedReport === this.REPORT_ROAD_SAFETY || this.selectedReport === this.REPORT_ROAD_INSPECTION)) {
               return true
           }
           return false
        },
        today: function() {
            const today = new Date();
            return today.toISOString().split('T')[0];
        }
    },
    created() {
        const date = new Date();
        date.setHours(0,0)
        this.reportDate = date
        this.reportOptions = [
            {text: this.$t("contracts.external.select_report_type"), value: null},
            {text: this.$t("contracts.external.material_report"), value: this.REPORT_MATERIAL},
            {text: this.$t("contracts.external.road_inspection_report"), value: this.REPORT_ROAD_INSPECTION},
            {text: this.$t("contracts.external.road_safety_report"), value: this.REPORT_ROAD_SAFETY},
            {text: this.$t("contracts.external.work_diary"), value: this.REPORT_WORK_DIARY},
            {text: this.$t("contracts.external.road_work_route"), value: this.REPORT_WORK_ROUTE},
           // {text: this.$t("contracts.external.equipment_inspection_report"), value: this.REPORT_EQUIPMENT_INSPECTION},
        ]

    },
    watch: {
        selectedReport: function (val) {
            if (val !== this.REPORT_EQUIPMENT_INSPECTION) {
                this.roads = []
            }
        }
    },
    methods: {
        updateReportDate: function (event) {
            const date = new Date(event)
            date.setHours(0,0)
            this.reportDate = date
            if (this.$refs.trips) {
                this.$refs.trips.resetSelection()
            }
            this.roads = []
        },
        createTripBasedReport(tripParams, trips) {
            const params = {
                "trips": trips
            }
            if (this.selectedReport === this.REPORT_EQUIPMENT_INSPECTION) {
                this.loading = true
                params.search_params = tripParams
                this.restFetchParams(this.tripRoadsUrl, params, this.handleRoadList)
            } else if (this.selectedReport === this.REPORT_ROAD_SAFETY || this.selectedReport === this.REPORT_ROAD_INSPECTION) {
                this.loading = true
                params.contract = this.contract.id
                this.restFetchParams(this.selectedReport === this.REPORT_ROAD_INSPECTION ? this.extRoadInspectionReportUrl : this.extRoadSafetyReportUrl,
                    params, this.handleSafetyPreview)
            } else if (this.selectedReport === this.REPORT_WORK_ROUTE) {
                this.loading = true
                this.restAdd(this.workCompleteBulkUrl, params, this.handleWorkReportResponse)
            }
        },
        handleWorkReportResponse(response) {
            this.loading = false
            if (response) {
                EventBus.$emit('show-alert', this.$t('contracts.external.route_reports_sent'), 'success')
            } else {
                EventBus.$emit('show-alert', this.$t('contracts.external.route_reports_failed'))
            }
        },
        handleRoadList(response) {
            this.loading = false
            if (response && response.data) {
                this.roads = response.data
            }
        },
        handleSafetyPreview(response) {
            this.loading = false
            if (response && response.data) {
               this.roadSafetyResult = response.data
            } else {
                EventBus.$emit('show-alert', this.$t('contracts.external.report_failed'))
            }
        },
        equipmentsSelected(value) {
            this.equipmentTypes = value
        },
        submitReport() {
            switch (this.selectedReport) {
                case this.REPORT_EQUIPMENT_INSPECTION:
                    this.submitEquipmentInspectionReport()
                    break;
                case this.REPORT_ROAD_SAFETY:
                    this.submitRoadSafetyReport()
                    break;
                case this.REPORT_ROAD_INSPECTION:
                    this.submitRoadInspectionReport()
                    break;
            }
        },
        submitRoadSafetyReport() {
            if (this.roadSafetyResult) {
                this.loading = true
                const params = {
                    contract: this.contract.id,
                    data: this.roadSafetyResult
                }
                this.restAdd(this.extRoadSafetyReportUrl, params, this.handleReportOk, this.handleReportFail)
            }
        },
        submitRoadInspectionReport() {
            if (this.roadSafetyResult) {
                this.loading = true
                const params = {
                    contract: this.contract.id,
                    data: this.roadSafetyResult
                }
                this.restAdd(this.extRoadInspectionReportUrl, params, this.handleReportOk, this.handleReportFail)
            }
        },
        submitEquipmentInspectionReport() {
            if (this.roads.length > 0 && this.equipmentTypes.length > 0) {
                this.loading = true
                let input = {
                    equipmentTypes: this.equipmentTypes,
                    roads: this.roads,
                    contract: this.contract.id
                }
                this.restAdd(this.extEquipmentInspectionUrl, input, this.handleReportOk, this.handleReportFail)
            }
        },
        submitDiary(diaryData) {
            if (diaryData) {
                this.loading = true
                const params = {
                    contract: this.contract.id,
                    data: diaryData
                }
                this.restAdd(this.extWorkDiaryUrl, params, this.handleReportOk, this.handleReportFail)
            }
        },
        handleReportOk() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('contracts.external.report_success'), 'success')
            this.equipmentTypes = []
            this.roads = []
        },
        handleReportFail() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('contracts.external.report_failed'))
        }
    }
}
</script>
