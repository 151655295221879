<template>
    <b-container fluid
                 class="details-container"
                 @click.stop="close"
    >
        <div
            class="col-sm-12 dynamic_width"
            @click.stop=""
        >
            <b-form class="col-sm-12 editor-form">
                <div class="col-sm-12 form-title editor-title">
                    <div>
                        <h2 class="form-title editor-title">
                            {{ observation.type.name }}
                        </h2>
                    </div>
                </div>
                <div class="col-sm-12 p-2">
                    <Flicking ref="flicking" :options="options" :plugins="plugins" v-if="observation.photos.length > 0" class="pointer nopads text-center"
                              style="height: 60vh">
                        <div v-for="(photo, index) in observation.photos" :key="photo.id" class="relative nopads stat-map-container slide-img-container">
                            <ImageView
                                ref="image"
                                :img-size="'lg'"
                                :url="getPhotoUrlForObservation(observationPhotoUrl, observation.id, photo)"
                                @click="openImage"
                                :contain="true"
                            />
                            <rotate-image-buttons :imageRef="getImageReference(index)"/>
                        </div>
                        <span slot="viewport" class="flicking-arrow-prev"></span>
                        <span slot="viewport" class="flicking-arrow-next"></span>
                    </Flicking>


                    <b-row class="nopads pt-1 pl-4">
                        <road-addess-view
                              :road-number="observation.road_number"
                              :section-number="observation.section_number"
                              :distance="observation.distance_from_sec_start"
                              :street-name="observation.street_name"
                        />
                    </b-row>
                    <b-row class="nopads pt-1 pl-4" v-if="observation.info">
                        <span class="item-detail-text">{{ observation.info }}</span>
                    </b-row>
                    <div class="col-sm-12 button-container pb-3">
                        <b-button
                            variant="secondary"
                            class="result-button"
                            @click.stop="close"
                        >
                            {{ $t('common.close') }}
                        </b-button>
                    </div>
                </div>
                <!-- Fullscreen photo -->
                <transition name="fade">
                    <fullscreen-image
                        v-if="fullScreenPhotoData"
                        :largeImages="fullScreenPhotoData"
                        :panelIndex="panelIndex"
                        :controls="true"
                        @close="closePhoto"/>
                </transition>
            </b-form>
     </div>
    </b-container>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {observationHelper} from '../mixins/ObservationMixin'
import {Arrow} from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";
import "@egjs/vue-flicking/dist/flicking.css";
import {Flicking} from "@egjs/vue-flicking";
import ImageView from "@/components/view/ImageView.vue";
import FullscreenImage from "@/components/view/FullscreenImage.vue";
import RoadAddessView from "@/components/velho/RoadAddressView";
import RotateImageButtons from '@/components/view/RotateImageButtons'

export default {
    name: 'ObservationPhotoViewer',
    components: {RoadAddessView, FullscreenImage, ImageView, Flicking, RotateImageButtons},
    mixins: [restApi, observationHelper],
    props: {
        observation: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data: function () {
        return {
            plugins: [new Arrow()],
            options: {
                preventDefaultOnDrag: true,
                bound: true,
            },
            fullScreenPhotoData: undefined,
            panelIndex: 0,
            imageRefs: [],
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.imageRefs = this.$refs.image;
        });
    },
    methods: {
        getPhotoUrlForObservation: function (baseUrl, observationId, photo) {
            return baseUrl + '/' + observationId + '/' + photo.id
        },
        close: function () {
            this.assignmentMode = false
            this.$emit('close')
        },
        openImage: function () {
            const flicking = this.$refs.flicking
            const imageData = this.$refs.image
            this.fullScreenPhotoData = []
            imageData.forEach(src => {
                this.fullScreenPhotoData.push(src)
            })
            this.panelIndex = flicking.index

        },
        closePhoto() {
            this.fullScreenPhotoData = undefined
        },
        getImageReference: function (index) {
            return this.imageRefs[index]
        }
    }
}
</script>

