<template>
    <div>
        <base-modal class="road-search-wizard">
            <div class="editor-form form-data">
                <b-row class="nopads">
                    <h2 class="title nopads">{{ $t('search_works.title') }}</h2>
                </b-row>
                <b-row class="nopads mb-2">
                    <div>
                        <span v-for="contract in contracts" class="span-title" :key="contract.id">{{ contract.name }}</span>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <span class="span-title">{{ $t('search_works.time_range') }}</span>
                </b-row>
                <b-row class="nopads m-2">
                    <div class="col-sm-6 pl-2 pr-2">
                        <span class="span-title">{{ $t('ins_report.from_date') }}</span>
                        <datetime
                            v-model="fromDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                    <div class="col-sm-6 pl-2 pr-2">
                        <span class="span-title">{{ $t('ins_report.to_date') }}</span>
                        <datetime
                            v-model="toDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                </b-row>
                <b-row class="nopads mb-2" v-if="roadData.name">
                    <span class="span-title title">{{ roadData.name }}</span>
                </b-row>
                <b-table class="works-table" v-if="this.works.length > 0" :items="works" :fields="[{ key: 'time', label: '' },{ key: 'work', label: '' },{ key: 'contract', label: '' }]">
                    <template v-slot:cell(time)="row">
                        {{ toLocalTime(row.value.start) }} - {{ toLocalTime(row.value.end) }}
                    </template>
                    <template v-slot:cell(contract)="row">
                        {{ row.value.name }}
                    </template>
                </b-table>

                <div class="button-container">
                    <b-button
                        class="result-button"
                        size="sm"
                        @click="$emit('close')"
                    >
                        {{ $t('common.close') }}
                    </b-button>
                    <b-button
                        variant="primary"
                        class="result-button"
                        size="sm"
                        @click.stop="searchWorks"
                        :disabled="isSearchDisabled"
                    >
                        {{ $t('common.search') }}
                    </b-button>
                </div>
            </div>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
                />
        </base-modal>
    </div>
</template>

<script>
import BaseModal from '../BaseModal'
import {restApi} from "@/components/mixins/RestApiMixin";
import {timeUtils} from '../mixins/TimeUtils'
import {EventBus} from "@/event-bus";

export default {
    name: 'WorkSearchWizard',
    mixins: [restApi, timeUtils],
    components: { BaseModal },
    props: {
        coordinates: {
            type: Object,
            default() {
                return null
            },
        },
        contracts: {
            type: Array,
            default() {
                return []
            },
        },
        map: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isSearchDisabled() {
            const from = new Date(this.fromDate);
            const to = new Date(this.toDate);

            return !this.coordinates || !this.contracts || this.contracts.length === 0 || this.loading || to < from
        }
    },
    mounted() {
        this.fromDate = this.getMonthStart()
        this.toDate = this.getDayStart()
        if(this.coordinates) {
            this.fetchRoadDataByCoordinates(this.coordinates)
        }
    },
    data() {
        return {
            loading: false,
            roadData: {
                name: null,
            },
            fromDate: '',
            toDate: '',
            /** @type {Array<{ work: String, endTime: Date, startTime: Date }>} */
            works: [],
        }
    },
    methods: {
        fetchRoadDataByCoordinates(coord) {
            this.restFetchParams(this.roadAddressUrl, {lat: coord.lat, lon: coord.lng}, (response) => {
                if(response.data) {
                    this.roadData = {
                        name: response.data.name
                    }
                }
            }, () => {})
        },
        async searchWorks() {
            try {
                this.loading = true
                this.works = []
                if(this.coordinates && this.contracts.length > 0) {
                    const worksData = []
                    await Promise.all(this.contracts.map(async contract => {
                        return new Promise((resolver) => {
                            const params = {
                                lat: this.coordinates.lat,
                                lon: this.coordinates.lng,
                                age: this.getHoursDifference(this.fromDate, this.toDate),
                                contract: contract.id
                            }
                            this.restFetchParams(this.routaWfsUrl, params, (response) => {
                                if(response.data && response.data.totalFeatures > 0) {
                                    response.data.features.forEach(feature => {
                                        worksData.push({
                                            time: {
                                                start: feature.properties.alkamisaika,
                                                end: feature.properties.paattymisaika
                                            },
                                            work: feature.properties.toimenpidenimi,
                                            contract: contract,
                                        })
                                    });
                                }
                                resolver()
                            })
                        })
                    }))

                    this.loading = false
                    worksData.sort((a, b) => new Date(a.time.start) - new Date(b.time.start))
                    this.works = worksData
                }
            } catch (_error) {
                this.loading = false
                EventBus.$emit('show-alert', this.$t('common.error'))
            }
        }
    }
}
</script>
<style scoped>
.title {
    color: black;
    font-weight: bold;
}
.works-table thead {
    display: none;
}
</style>