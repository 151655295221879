<template>
    <multiselect
        v-model="selectedMaterial"
        class="multiselect_routa"
        :placeholder="$t('spreader_data.select_material')"
        :deselect-label="$t('common.remove_selection')"
        :select-label="$t('common.select')"
        :close-on-select="true"
        label="text"
        track-by="routaSelectionValue"
        :searchable="true"
        :internal-search="true"
        :multiple="false"
        :group-select="false"
        :selected-label="$t('common.selected')"
        :options="this.materialOptions"
    >
        <template
            slot="singleLabel"
            slot-scope="props"
        >
            <span class="option__desc">
                <span class="option__title">{{ props.option.text}}</span>
            </span>
        </template>
        <template
            slot="option"
            slot-scope="props"
        >
            <div class="option__desc">
                <span class="option__title multiselect_detail_block">
                    {{ props.option.$isLabel ? props.option.$groupLabel : props.option.text}}
                </span>
            </div>
        </template>
        <span slot="noResult">{{ $t('common.not_found') }}</span>
        <span slot="noOptions">{{ $t('common.no_results') }}</span>
    </multiselect>
</template>
<script>
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";

export default {
    name: 'SpreaderMaterialSelector',
    components: {},
    mixins: [spreaderDataMixin],
    props: {
        material: {
            type: Number,
            default: null
        },
        materialType: {
            type: Number,
            default: 1 // 1 = dry, 2 = liquid, 3 = both
        }
    },
    watch: {
        selectedMaterial: function () {
            const materialData = {
                material: this.selectedMaterial !== null ? this.selectedMaterial.value : null,
                system: this.selectedMaterial !== null ? this.selectedMaterial.system : null,
                materialType: this.selectedMaterial !== null ? this.selectedMaterial.type : null
            }

            this.$emit('onMaterialSelection', materialData)
        },
    },
    data: function () {
        return {
            selectedMaterial: null,
            materialOptions: []
        }
    },
    mounted: function () {
        this.materialOptions = this.getMaterials()
        if(this.material !== null) {
            const option = this.materialOptions.find(material => material.value === this.material)
            if(option) {
                this.selectedMaterial = option
            }
        }
    },
    methods: {
        getMaterials: function (type) {
            // Selector internal value for tracking selection
            let routaIndex = 0
            const materials = []
            const typeSelection = !type ? this.materialType : type

            if(typeSelection === this.materialTypes.BOTH) {
                materials.push(...this.getDryMaterials().map(material => {
                    material.text = `${material.text} - ${this.$t('spreader_data.dry')}`
                    return material
                }))
                materials.push(...this.getLiquidMaterials().map(material => {
                    material.text = `${material.text} - ${this.$t('spreader_data.liquid')}`
                    return material
                }))
            } else if(typeSelection === this.materialTypes.DRY) {
                materials.push(...this.getDryMaterials())
            } else {
                materials.push(...this.getLiquidMaterials())
            }

            materials.forEach(material => {
                material.routaSelectionValue = routaIndex
                routaIndex++
            })
            
            return materials
        },
    }
}
</script>