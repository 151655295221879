<template v-slot:modal-footer>
    <div class="col-sm-12 nopads">
        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <b-row class="p-2">
            <div class="col-sm-4 p-2">
                <span class="span-title">
                    {{ $t('vehicle_editor.registration_number') }}
                </span>
                <b-form-input
                    id="license_plate"
                    :state="!!spreaderData.vehicle.license_plate"
                    v-model="spreaderData.vehicle.license_plate"
                    type="text"
                    size="sm"
                />
            </div>
            <div class="col-sm-4 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.dry_material') }}
                </span>
                <spreader-material-selector
                    :materialType="this.materialTypes.DRY"
                    :material="this.spreaderData.material_dry"
                    @onMaterialSelection="setMaterial"
                />
            </div>
            <div class="col-sm-4 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.dry_amount') }}
                </span>
                <b-input-group size="sm" :append="item.material_dry_unit">
                    <b-form-input type="number" min="0.00" v-model="spreaderData.total_dry_amount"></b-form-input>
                </b-input-group>

            </div>
            <div class="col-sm-4 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.liquid_material') }}
                </span>
                <spreader-material-selector
                    :materialType="this.materialTypes.LIQUID"
                    :material="this.spreaderData.material_liquid"
                    @onMaterialSelection="setMaterial"
                />
            </div>
            <div class="col-sm-4 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.liquid_amount') }}
                </span>
                <b-input-group size="sm" :append="item.material_liquid_unit">
                    <b-form-input size="sm" type="number" min="0.00" v-model="spreaderData.total_liquid_amount"></b-form-input>
                </b-input-group>
            </div>
            <div class="col-sm-4 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.spreader') }}
                </span>
                <span>
                    {{ item.name ? item.name : '-' }}
                </span>
            </div>
            <div class="col-sm-4 p-2">
                <span class="span-title">
                    {{ $t('spreader_data.time') }}
                </span>
                <span>
                    {{ item.start_time ? toLocalTime(item.start_time) : '' }} -  {{ item.end_time ? toLocalTime(item.end_time) : '' }}
                </span>
            </div>
        </b-row>
        <hr class="p-2 mb-1 mt-1"/>
        <b-row class="nopads mb-2">
            <div class="col-sm-12">
                <span class="span-title">{{ $t('spreader_data.edit_spreader_data') }}</span>
            </div>
        </b-row>
        <b-row class="nopads">
            <div class="col-sm-12">
                <span class="span-title">{{ $t('spreader_data.edit_spreader_order') }}</span>
                <b-row class="nopads">
                    <div class="col-sm-6 nopads pr-1" style="margin-top: -3px">
                        <open-contract-filter
                            ref="contracts"
                            :to="item.end_time"
                            :from="item.start_time"
                            :show-company="false"
                            :showOnlyMyContractsOption="true"
                            :show-title="false"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                        />
                    </div>

                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <contract-order-filter
                            v-model="order"
                            :contract-id="contract ? contract.id : undefined"
                            :show-executor-text="true"
                            :show-title="false"
                        />
                    </div>
                </b-row>
            </div>
        </b-row>
        <b-row class="nopads">
            <div class="col-sm-12 button-container">
                <b-button
                variant="danger"
                class="result-button"
                @click.stop="$emit('close')"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="result-button"
                    :disabled="isSendDisabled"
                    @click.stop="sendDataToHarja"
                >
                    {{ $t('common.send') }}
                </b-button>
            </div>
        </b-row>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        ></div>
    </div>
</template>
<script>
import {EventBus} from '@/event-bus'
import {restApi} from "@/components/mixins/RestApiMixin";
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";
import {timeUtils} from '@/components/mixins/TimeUtils'
import SpreaderMaterialSelector from "@/components/materials/spreaderdata/SpreaderMaterialSelector"
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import ContractOrderFilter from "@/components/order/ContractOrderFilter";

export default {
    name: 'SpreaderDataHarjaSender',
    props: {
        item: {
            type: Object,
            default() {
                return ({})
            }
        },
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
    },
    components: {ContractOrderFilter, OpenContractFilter, SpreaderMaterialSelector},
    mixins: [timeUtils, restApi, spreaderDataMixin],
    created() {
        const clonedData = JSON.parse(JSON.stringify(this.item))
        this.spreaderData = clonedData
        if (!this.spreaderData.vehicle) {
            this.spreaderData.vehicle = {
                license_plate: null,
            }
        }
    },
    computed: {
        isSendDisabled() {
            if(this.loading) { return true }
            if(!this.spreaderData.vehicle.license_plate) { return true }

            return false
        }
    },
    data: function () {
        return {
            loading: false,
            spreaderData: {},
            customer: null,
            contractor: null,
            contractName: null,
            company: null,
            orders: [],
            order: null,
            contract: null,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            variant: 'danger',
            alertMessage: ''
        }
    },
    methods: {
        setCustomer: function (customer) {
            this.customer = customer
        },
        setCompany: function (company) {
            this.company = company
        },
        setContractor: function (contractor) {
            this.contractor = contractor
        },
        setContract: function (contract) {
            this.contract = contract
        },
        /**
         * @param  {{materialType: number, material: number|null, system: number}} material
         */
        setMaterial: function (material) {
            if(material.materialType === this.materialTypes.DRY) {
                this.spreaderData.material_dry = material.material
                this.spreaderData.dry_material_system = material.system
            } else {
                this.spreaderData.material_liquid = material.material
                this.spreaderData.liquid_material_system = material.system
            }
        },
        sendDataToHarja: function () {
            this.loading = true
            if(this.spreaderData && this.spreaderData.id) {
                const vehicle = {
                    ...this.spreaderData.vehicle,
                    license_plate: this.spreaderData.vehicle.license_plate ? this.spreaderData.vehicle.license_plate.trim() : null
                }
                const data = {
                    id: this.spreaderData.id,
                    vehicle: vehicle,
                    material_dry: this.spreaderData.material_dry ? Number(this.spreaderData.material_dry) : null,
                    material_liquid: this.spreaderData.material_liquid ? Number(this.spreaderData.material_liquid) : null,
                    total_dry_amount: this.spreaderData.total_dry_amount ? Number(this.spreaderData.total_dry_amount) : null,
                    total_liquid_amount: this.spreaderData.total_liquid_amount ? Number(this.spreaderData.total_liquid_amount) : null,
                    order: this.order
                }
                this.restAdd(this.spreaderDataToHarjaUrl, data, (response) => {
                this.loading = false

                // Update the original item with edited data
                this.item.vehicle = response.vehicle
                this.item.material_dry = response.material_dry
                this.item.material_liquid = response.material_liquid
                this.item.total_dry_amount = response.total_dry_amount
                this.item.total_liquid_amount = response.total_liquid_amount

                this.$emit('close')
                EventBus.$emit('show-alert', this.$t('spreader_data.alert_send_success'), 'success')
                }, (response) => {
                    this.loading = false
                    if(response && response.data && response.data.code) {
                        switch(response.data.code) {
                            case 1:
                                this.showAlert(this.$t('spreader_data.errors.1'))
                                break
                            case 2:
                                this.showAlert(this.$t('spreader_data.errors.2'))
                                break
                            case 3:
                                this.showAlert(this.$t('spreader_data.errors.3'))
                                break
                            default:
                                this.showAlert(this.$t('spreader_data.alert_send_fail'))
                                break
                        }
                    } else {
                        this.showAlert(this.$t('spreader_data.alert_send_fail'))
                    }
                })
            }
        },
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
    }
}
</script>
