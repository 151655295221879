<template>
    <div
        class="details-container"
    >
        <div
            class="details dynamic_width"
        >
            <div
                class="trip-results"
                style="height:70vh !important;"
            >
                <map-container
                    ref="mapContainer"
                    :menu-items="menuItems"
                    :find-user="false"
                    @onMapClicked="mapClicked"
                    @onDrag="onDrag"
                    @onDragEnd="onDragEnd"
                    @onMapChanged="mapChanged"
                />
                <div class="map-trip-details-info-container pl-1 pb-1 pr-1" style="float: left">
                    <b-collapse visible id="map-trip-details-info">
                        <div v-if="summaryItem">
                            <b-row class="summary-text" style="font-size: .8em;">
                                {{ (summaryItem.date.replace('T00:00:00', '')) }}
                            </b-row>
                            <b-row class="summary-text upper-text">
                                {{ summaryItem.contract }}
                            </b-row>
                            <b-row class="summary-text">
                                {{ summaryItem.order ? getOrderAndExecutorStr(summaryItem.order) : '-' }}
                            </b-row>
                            <b-row class="summary-text">
                                {{ summaryItem.vehicle }},
                                {{ summaryItem.driver }}
                            </b-row>
                            <hr class="orange-divider"/>
                            <b-row class="summary-text">
                                {{ $t('trip_list.duration') + ' (H:mm): ' +  this.getWorkTime(this.sumDuration) }}
                            </b-row>
                            <b-row class="summary-text">
                                {{ $t('trip_list.trip_length') +  ': ' + this.sumLength.toFixed(1) }}
                            </b-row>
                            <b-row class="summary-text">
                                {{ $t('daily_summary.road_distance') + ': ' + this.sumRoadCoverage.toFixed(1) }}
                            </b-row>
                            <b-row class="summary-text">
                                <b>{{ $t('daily_summary.total_weight') + ': ' + this.totalWeight.toFixed(1) + ' ' + 't' }}</b>
                            </b-row>
                        </div>
                    </b-collapse>
                    <div class="button-container nopads">
                        <b-button id="contract-trip-toggle" v-b-toggle.map-trip-details-info>
                        <span class="toggle-opened">
                            <font-awesome-icon icon="angle-double-up"/>
                        </span>
                        <span class="toggle-closed">
                            <font-awesome-icon icon="angle-double-down"/>
                        </span>
                        </b-button>
                    </div>
                </div>
                <div class="map-controls mt-1">
                    <div class="map-controls__list col-12 col-lg-4 nopads">
                        <measure-distance-map
                            v-if="measureDistanceEnabled"
                            ref="measureDistances"
                            :map="map"
                            :measure-distance-results="measureDistances"
                            :measure-distance-target-item="measureDistanceTargetItem"
                            :measure-distance-target-item-callback="measureDistanceTargetItemCallback"
                            :draggable="true"
                            @onRemoveMeasureDistances="removeMeasureDistances"
                        />
                        <measure-road-map
                            v-if="measureRoadEnabled"
                            ref="measureRoad"
                            :map="map"
                            :hide-make-new-observation-button="true"
                            :draggable="true"
                            @close="measureRoadEnabled = false"
                            @confirmSelection="createLineObservation"
                        />
                    </div>
                </div>
                <trip-map
                    v-if="map"
                    ref="tripMap"
                    :trip-results="trips"
                    :trip-item="mapItem"
                    :map="map"
                    :show-trip-info="false"
                    :area-group="areaGroup"
                    @setCurrentPoint="setCurrentPoint"
                    @carPositionSimulating="setCarPositionSimulating"
                />
                <trip-map
                    v-if="map"
                    ref="tripAllTripsMap"
                    :trip-results="previousOrderTrips"
                    :map="map"
                    :show-trip-info="false"
                    :focus="false"
                    :line-opacity="0.5"
                    :line-width="1"
                    static-line-color="#000000"
                />
            </div>
            <div class="map-trip-details-table pl-1 pt-1 pr-1">
                <b-table
                    show-empty
                    small
                    selectable
                    ref="selectableTable"
                    :select-mode="selectMode"
                    selected-variant="success"
                    striped hover
                    :items="tableItems"
                    :fields="fields"
                    @row-selected="showTripOnMap"
                >
                    <template v-slot:cell(date)="row">
                        {{ getShortDateString(row.value) }}
                    </template>
                    <template v-slot:cell(tripnote)="row">
                        <span v-if="row.item.tripnotes && row.item.tripnotes.length > 0">
                            <b-tooltip :target="'notes'+row.item.id" triggers="hover" placement="left">
                                <span
                                    v-for="tripNote in row.item.tripnotes"
                                    v-bind:key="tripNote.id">
                                    <span class="tooltip-title">{{ toLocalTime(tripNote.start_time) }}-{{ toLocalTime(tripNote.end_time) }}</span>
                                    {{ tripNote.note }}
                                </span>
                            </b-tooltip>
                            <font-awesome-icon
                                :id="'notes'+row.item.id"
                                icon="comment"
                                class="note"
                            />
                        </span>
                    </template>
                </b-table>
            </div>
            <b-row class="nopads">
                <div class="col-md-6 nopads">
                    <div v-if="mapItem" class="button-container" style="text-align: left">
                        <b-button
                            class="result-button"
                            style="min-width: 7em"
                            variant="primary"
                            :disabled="allTrips === true"
                            @click="showAllTrips"
                        >
                            {{ $t('common.show_all') }}
                        </b-button>
                        <b-button
                            class="result-button"
                            style="min-width: 7em"
                            variant="success"
                            :disabled="mapItem == null || this.withGeom === false"
                            @click="simulateTrip"
                        >
                            {{ $t('trip.simulate_trip') }}
                        </b-button>
                    </div>
                </div>
                <div class="col-md-6">
                    <b-row class="mb-2" align-h="end" align-v="center">
                        <b-form-checkbox v-if="allowPreviousTripsFetching" :disabled="loading > 0" size="sm" v-model="showOrderAllTrips" switch class="mr-4">
                            {{ $t('trip.show_all_order_previous_trips') }}
                        </b-form-checkbox>
                        <b-button
                            variant="primary"
                            class="result-button"
                            @click.stop="close"
                        >
                            {{ $t('common.close') }}
                        </b-button>
                    </b-row>
                </div>
            </b-row>
            <map-layer-selector
                v-if="mapLayerSelector"
                @close="mapLayerSelector = false"
                @onMapSelected="onMapSelected"/>
        </div>

        <b-modal
            ref="roadCoverageModal"
            centered
            hide-footer
            hide-header-close
            cancel-disabled
            :title="$t('daily_summary.road_coverage_calculation')">
            <b-row>
                <font-awesome-icon icon="exclamation-triangle" class="hint-text" style="margin-right: 1em; margin-top: .2em"/>
                <span class="hint-text">{{ $t('daily_summary.road_coverage_disclaimer')}}</span>
            </b-row>
            <b-row class="pt-2">
                <b-form-checkbox v-model="roadCoverage" switch>
                    {{ $t('daily_summary.road_coverage') }}
                </b-form-checkbox>
            </b-row>
            <div class="button-container">
                <b-button
                    variant="primary"
                    class="form-button"
                    @click.stop="fetchTrips">
                    <span class="map-button-text">{{ $t('common.continue') }}</span>
                </b-button>
            </div>
        </b-modal>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />

    </div>
</template>

<script>
import {orderMixin} from "@/components/mixins/OrderMixin";
import MapContainer from "../map/MapContainer";
import TripMap from '../trip/TripMap'
import {EventBus} from '@/event-bus'
import {restApi} from "../mixins/RestApiMixin";
import {timeUtils} from '../mixins/TimeUtils'
import MeasureDistanceMap from "../map/MeasureDistanceMap";
import MeasureRoadMap from "../map/MeasureRoadMap";
import {measureDistanceMixin} from "../mixins/MeasureDistanceMixin";
import MapLayerSelector from "../map/MapLayerSelector";
import {vehicleHelper} from "../mixins/VehicleMixin";


export default {
    name: "ContractTripDetails",
    components: {MapLayerSelector, MapContainer, TripMap, MeasureDistanceMap, MeasureRoadMap},
    mixins: [orderMixin, restApi, timeUtils, measureDistanceMixin, vehicleHelper],
    props: {
        summaryItem: {
            type: Object,
            default: null
        },
        orderItem: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            sumDuration: 0,
            sumRoadCoverage: 0,
            roadCoverage: false,
            sumLength: 0,
            totalWeight: 0,
            currentPoint: 0,
            currentTime: null,
            currentSpeed: 0,
            isCarSimulating: false,
            vehicle: null,
            map: null,
            order: null,
            withGeom: false,
            tableItems: [],
            previousOrderTrips: [],
            trips: [],
            loading: 0,
            mapItem: null,
            selectMode: 'single',
            areaGroup: null,
            allTrips: true,
            mapLayerSelector: false,
            measureRoadEnabled: false,
            showOrderAllTrips: false,
            measureDistances: [],
            measureDistanceEnabled: false,
            measureDistanceTargetItem: null,
            orderGeometry: null,
            allowPreviousTripsFetching: false,
            fields: [
                {
                    start_time: {
                        label: this.$t('trip_list.start_time'),
                        sortable: true
                    }
                },
                {
                    end_time: {
                        label: this.$t('trip_list.end_time'),
                        sortable: true
                    }
                },
                {
                    mode: {
                        label: this.$t('trip_list.mode'),
                        sortable: true
                    }
                },
                {
                    duration: {
                        label: this.$t('trip_list.duration') + ' (min)',
                        sortable: true
                    }
                },
                {
                    trip_length: {
                        label: this.$t('trip_list.trip_length'),
                        sortable: true
                    }
                },
                {
                    road_coverage: {
                        label: this.$t('daily_summary.road_distance'),
                        sortable: true
                    }
                },
                {
                    delivery: {
                        label: this.$t('contract_reports.unload_records.delivery_id'),
                        sortable: true
                    }
                },
                {
                    tripnote: {
                        label: this.$t('daily_summary.trip_notes'),
                        sortable: false
                    }
                }
            ]
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.map = this.$refs.mapContainer.getMap()
            if (this.summaryItem.unhandled_coverage_areas) {
                this.map.addGeoJsonObjects(this.summaryItem.unhandled_coverage_areas, false, '#9e0c0c', false, 0.5)
            }
        })
        this.$refs.roadCoverageModal.show()
        this.fetchAreaGroup()
        this.fetchOrderGeometry()
        this.totalWeight = this.summaryItem && this.summaryItem.delivery_tons
        this.allowPreviousTripsFetching = this.orderItem && this.orderItem.pricing_model && parseInt(this.orderItem.pricing_model.unit1) === 4 &&
            parseInt(this.orderItem.pricing_model.unit2) === 108
    },
    computed: {
        menuItems() {
            return [
                !this.measureDistanceEnabled ? {
                    text: this.$t('map.measure_distance'),
                    onClick: this.initMeasureDistance
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureDistances
                },
                !this.measureRoadEnabled ? {
                    text: this.$t('map.measure_road'),
                    onClick: this.initMeasureRoad
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureRoads
                },
                {
                    text: this.$t('map.layers'),
                    onClick: this.showMapLayerSelector
                },
            ]
        }
    },
    watch: {
        showOrderAllTrips() {
            this.allOrderTripsChanged()
        },
        orderItem() {
            this.allowPreviousTripsFetching = this.orderItem && this.orderItem.pricing_model && parseInt(this.orderItem.pricing_model.unit1) === 4 &&
                parseInt(this.orderItem.pricing_model.unit2) === 108
        }
    },
    methods: {
        sumTrips: function () {
            if (this.trips.length > 0) {
                this.trips.forEach(function (trip) {
                    this.sumRoadCoverage += trip.road_coverage ? trip.road_coverage / 1000 : 0
                    this.sumLength += trip.length
                    let duration = this.getDurationInMinutes(trip.start_time, trip.end_time)
                    this.sumDuration += duration
                }, this)
            }
        },
        onDrag: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
            }
        },

        onDragEnd: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
            }
            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(data)
            }
        },

        mapClicked: function (coord) {
            if (this.measureDistanceEnabled) {
                this.addMeasureDistance(coord)
            }

            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(coord)
            }
        },
        fetchAreaGroup: function() {
            if (this.summaryItem.area_id) {
                this.areaGroup = null
                this.loading++
                this.restFetch(this.areaGroupUrl + '/id/' + this.summaryItem.area_id, this.handleAreaGroupResponse)
            }
        },
        handleAreaGroupResponse: function (response) {
            this.loading--
            if (response) {
                this.areaGroup = response.data
            }
        },
        initTableItems: function () {
            this.tableItems = []
            if (this.trips.length > 0) {
                this.trips.forEach(function (item) {
                    this.tableItems.push({
                        start_time: this.toLocalTime(item.start_time),
                        end_time: this.toLocalTime(item.end_time),
                        duration: this.getDurationInMinutes(item.start_time, item.end_time),
                        mode:  this.getTripModeString(item.mode),
                        trip_length: item.length.toFixed(2),
                        road_coverage: item.road_coverage ? (item.road_coverage/1000).toFixed(2) : '-',
                        id: item.id,
                        delivery: item.delivery_amount ? item.delivery_amount + ' ' + item.delivery_unit : '-',
                        tripnotes: item.tripnotes
                    })
                }, this)
            }
        },
        close: function () {
            this.$refs.tripMap.resetMap()
            this.$emit('close')
        },
        simulateTrip: function () {
            this.$refs.tripMap.showMovingCar()
        },
        setCurrentPoint: function (currentPoint) {
            this.currentPoint = currentPoint
            // Check if mapItem and relevant arrays are available
            if (this.mapItem && this.mapItem.timestamps && this.mapItem.timestamps.length > 0 && this.mapItem.geometry && this.mapItem.geometry.length > 0) {
                // Get current timestamp and geometry
                let currentTime = this.mapItem.timestamps[this.currentPoint];
                this.currentTime = currentTime.substring(11, 16)
                let currentGeometry = this.mapItem.geometry[this.currentPoint];

                // Check if there are consecutive points available
                if (this.currentPoint < this.mapItem.timestamps.length - 1) {
                    // Get next timestamp and geometry
                    let nextTime = this.mapItem.timestamps[this.currentPoint + 1];
                    let nextGeometry = this.mapItem.geometry[this.currentPoint + 1];

                    // Calculate speed between current and next points
                    let speed = this.calculateSpeed(
                        currentGeometry.x, currentGeometry.y,
                        nextGeometry.x, nextGeometry.y,
                        currentTime, nextTime
                    );
                    if(speed !== Infinity) {
                        this.currentSpeed = speed.toFixed(2)
                    }
                }
            }
        },
        setCarPositionSimulating(isCarPositionSimulating) {
            this.isCarSimulating = isCarPositionSimulating
        },
        fetchTrips: function () {
            this.$refs.roadCoverageModal.hide()
            let params = {}
            this.trips = []
            this.tableItems = []
            if(this.summaryItem) {
                params.user = this.summaryItem.driver_id
                params.vehicle = this.summaryItem.vehicle_id
                params.order = this.summaryItem.order ? this.summaryItem.order.id : undefined
                params.customer = this.summaryItem.customer_id
                params.contractor = this.summaryItem.contractor_id
                params.contract = this.summaryItem.contract_id
                params.area_group = this.summaryItem.area_id && this.summaryItem.report_tracking_mode === 1 ? this.summaryItem.area_id : undefined // Use area only if tracking is limited inside area(s)
                params.with_geom = 1
                params.road_coverage = this.roadCoverage ? 1 : 0,
                params.trip_notes = 1
                params.from = new Date(this.summaryItem.date).toISOString()
                params.to = new Date(this.summaryItem.date.replace('00:00:00', '23:59:59')).toISOString()
                params.timestamps = 1
                params.speed = 1
                if (!this.summaryItem.order) {
                    params.empty_order = true
                }
                this.loading++
                this.restFetchParams(this.tripUrl, params, this.handleResponseTrips, this.handleError)
            }
        },
        handleResponseTrips: function (response) {
            this.loading--
            this.trips = response.data
            this.initTableItems()
            this.sumTrips()
        },
        fetchAllTrips() {
            let params = {}
            if(this.summaryItem && this.summaryItem.order.id) {
                this.previousOrderTrips = []
                params.order = this.summaryItem.order.id
                params.with_geom = 1
                params.from = new Date(this.orderItem.create_time).toISOString()
                params.to = new Date(this.summaryItem.date).toISOString()
                this.loading++
                this.restFetchParams(this.tripUrl, params, this.handleResponseAllTrips, this.handleError)
            }
        },

        handleResponseAllTrips: function (response) {
            this.loading--
            this.previousOrderTrips = response.data
            if(this.previousOrderTrips.length > 0) {
                this.showOrderAllTrips = true
                this.previousOrderTrips.forEach(trip => {
                    trip.mode = 1
                })
            } else {
                EventBus.$emit('show-alert', this.$t('trip_list.err_no_previous_trips'))
            }
        },

        handleError: function () {
            this.loading--
            EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
        },
        showTripOnMap: function (selectedItems) {
            let item = selectedItems && selectedItems.length > 0 ? selectedItems[0] : null
            if (!item) {
                this.mapItem = null;
                this.$refs.tripMap.resetMap()
                this.showAllTrips()
            } else if (!this.mapItem || this.mapItem.id != item.id) {
                this.allTrips = false
                this.mapItem = null
                if (this.$refs.tripMap) {
                    this.$refs.tripMap.resetMap()
                }
                let trip = this.trips.find(trip => trip.id === item.id)
                if (trip.geometry.length > 1) {
                    this.withGeom = true
                    this.mapItem = trip
                } else {
                    this.withGeom = false
                }
            }
        },

        showAllTrips: function () {
            this.loading++
            this.$refs.selectableTable.clearSelected()
            this.mapItem = null
            this.allTrips = true
            this.loading--
        },

        showMapLayerSelector: function () {
            this.mapLayerSelector = true
        },

        async onMapSelected(layer) {
            this.loading++
            await this.$refs.mapContainer.setMap(layer);
            await this.$refs.mapContainer.saveMapSettings();
            this.mapLayerSelector = false
            this.loading--
        },

        mapChanged(map) {
            this.map = map
        },

        allOrderTripsChanged() {
            if(this.showOrderAllTrips && this.previousOrderTrips.length === 0) {
                this.fetchAllTrips()
            } else if(this.showOrderAllTrips) {
                this.$refs.tripAllTripsMap.initView()
            } else {
                this.$refs.tripAllTripsMap.hideTrips()
            }
        },

        fetchOrderGeometry: function () {
            if (this.summaryItem.order && this.summaryItem.order.id) {
                this.loading++
                this.restFetch(this.orderUrl+'/'+this.summaryItem.order.id+'/geometry',this.handleOrderGeometry)
            }
        },

        handleOrderGeometry: function (response) {
            this.loading--
            if (response && response.data) {
                this.orderGeometry = response.data.length > 0 ? response.data : null
                if (this.orderGeometry && this.orderGeometry.length > 0) {
                    this.addGeoJsonMapObjects()
                }
            }
        },

        addGeoJsonMapObjects() {
            this.orderGeometry.forEach((item) => {
                if(item.visibility) {
                    this.map.addGeoJsonObjects(JSON.stringify(item.geometry), false, item.color, item.add_polyline_ends,
                        item.transparency ? item.transparency : 1, item.line_width ? item.line_width : 3)
                }
            })
        }

    }
}
</script>
<style>

.map-trip-details-table {
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.7);
    overflow-x: scroll;
    overflow-y: scroll;
    max-height: 10em;
}

.collapsed > .toggle-opened,
:not(.collapsed) > .toggle-closed {
    display: none;
}

.map-trip-details-info-container {
    background-color: rgba(43, 43, 43, 0.8);
    border: 1px solid #404041;
    min-width: 20em;
    z-index: 2;
    margin: .5em;
    position: relative;
    width: fit-content;
    border-radius: .5em;
}

#contract-trip-toggle {
    background: none;
    border: none;
    color: #FFFFFF;
    box-shadow: none;
}

.summary-text {
    color: #FFFFFF;
    text-shadow: 1px 1px #000000;
    font-size: .9em;
}

.upper-text {
    text-transform: uppercase;
}

.orange-divider {
    border-color: #90b677;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: .1em;
    margin-right: .1em;
}

</style>
