<template>
    <div class="col-sm-12 nopads">
        <div>
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                class="pl-2 pr-2"
            >

                <b-row>
                    <div class="col-sm-6 nopads pl-1 pr-1 mt-1">
                        <span class="search-title">{{ $t('contract_diary.start') }} </span>
                        <b-form-input
                            v-model="date"
                            type="date"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <open-contract-filter
                            :from="date"
                            :show-company="false"
                            :showOnlyMyContractsOption="true"
                            :contract="preSelectedContract"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                        />
                    </div>
                </b-row>

            </b-form>
        </div>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import OpenContractFilter from "@/components/contract/OpenContractFilter.vue";

export default {
    name: 'ContractDiarySearch',
    components: {OpenContractFilter},
    mixins: [restApi, timeUtils],
    props: {
        preSelectedContract: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            contract: null,
            date: null,
        }
    },
    watch: {
        date() {
            this.$emit('dateChange', this.date)
        },
        contract() {
            this.$emit('contractSelected', this.contract)
        }
    },
    mounted: function () {
        if (!this.date) {
            this.date = this.timestampToDateInput(this.getYesterdayAsDate().getTime())
        }
    },
    methods: {
        setContract(contract) {
            this.contract = contract
        },
    }
}
</script>
