<template>
    <div
        class="details-container">
        <Flicking ref="flicking" :options="options" :plugins="plugins" v-if="photos.length > 0"
                  style="height: 90%">
            <div v-for="(image, index) in this.photos" :key="index">
                 <div ref="image" v-bind:style="{ backgroundImage: 'url(' + image + ')' }" class="large-image"/>
                 <rotate-image-buttons v-if="controls" :imageRef="getImageReference(index)"/>
            </div>
            <span slot="viewport" class="flicking-arrow-prev"></span>
            <span slot="viewport" class="flicking-arrow-next"></span>
        </Flicking>
        <font-awesome-icon icon="times-circle" class="close-dialog-icon colored-border" @click.stop="$emit('close')"></font-awesome-icon>
        <transition name="fade">
            <div v-if="this.photos.length === 0" class="fullscreen-image-loading">{{ $t('common.loading') }}</div>
        </transition>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {Arrow} from "@egjs/flicking-plugins";
import {Flicking} from "@egjs/vue-flicking";
import RotateImageButtons from '@/components/view/RotateImageButtons'

export default {
    name: 'FullscreenImage',
    components: {Flicking, RotateImageButtons},
    mixins: [restApi],
    props: {
        url: null,
        largeImages: {
            type: Array,
            default() {
                return []
            }
        },
        panelIndex: {
            type: Number,
            default() {
                return 0
            }
        },
        controls: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    watch: {
        photos: {
            handler() {
                this.$nextTick(() => {
                    this.imageRefs = this.$refs.image;
                });
            },
            deep: true
        }
    },
    data() {
        return {
            plugins: [new Arrow()],
            options: {
                preventDefaultOnDrag: true,
                circular: true,
                panelsPerView: 1,
            },
            photos: [],
            imageRefs: [],
        }
    },

    mounted() {
        this.photos = []
        if (this.largeImages.length > 0) {
            this.largeImages.forEach(image => {
                this.photos.push(image.src)
            })
            let index = this.panelIndex === undefined ? 0 : this.panelIndex
            if (index > 0) {
                this.$nextTick(() => {
                    //refs requires that element is rendered
                    this.$refs.flicking.moveTo(index)
                })
            }

        } else {
            this.fetchPhoto()
        }

    },
    methods: {

        fetchPhoto() {
            this.loading = true
            this.restFetch(this.url, this.handleResponse)
        },
        handleResponse(response) {
            if (response && response.status === 200) {
                this.photos.push(response.data)
            }
        },
        getImageReference: function (index) {
            return this.imageRefs[index]
        }
    }
}
</script>

<style>

.large-image {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: transform 0.3s ease;
}

.colored-border {
    border: 0.07em solid black;
    background-color: black;
    border-radius: 50%;
}

</style>
