<template>
    <div class="rotate-button-container">
        <b-button
            variant="success"
            @click.stop="rotateLeft"
        >
            <i class="fa fa-undo"/>
        </b-button>
        <b-button
            variant="success"
            @click.stop="rotateRight"
        >
            <i class="fa fa-repeat"/>
        </b-button>
    </div>
</template>

<script>

export default {
    name: 'RotateImageButtons',
    mixins: [],
    props: {
        imageRef: {
            type: Object,
            default: null,
            required: false
        },
    },
    watch: {
        rotationAngle() {
            this.rotateImage()
        }
    },
    data() {
        return {
            rotationAngle: 0
        }
    },
    methods: {
        rotateRight() {
            this.rotationAngle = (this.rotationAngle + 90) % 360
        },
        rotateLeft() {
            this.rotationAngle = (this.rotationAngle - 90 + 360) % 360
        },
        rotateImage() {
            const img = this.imageRef ? this.imageRef.$el ? this.imageRef.$el : this.imageRef : null
            if (img) {
                img.style.transform = `rotate(${this.rotationAngle}deg)`;
                if (this.rotationAngle % 180 !== 0) {
                    const containerHeight = img.parentElement.clientHeight
                    const containerWidth = img.parentElement.clientWidth
                    const imgHeight = img.clientHeight
                    const imgWidth = img.clientWidth
                    const scale = Math.min(containerHeight / imgWidth, containerWidth / imgHeight)
                    img.style.transform += ` scale(${scale})`
                }
            }
        },
    }
}
</script>
<style scoped>
.rotate-button-container {
    display: flex;
    width: 100%;
    gap: 10px;
    bottom: 5px;
    z-index: 2;
    position: absolute;
    justify-content: center;
}
</style>