<template>
    <div class="content-container">
        <b-navbar variant="faded">
            <div class="header">
                <span
                    class="title"
                >
                  {{ $t('menu.admin') }} / {{ $t('menu.license_report') }}
                </span>
            </div>
        </b-navbar>
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <div class="col-sm-4">
                        <b-form-group
                            class="nopads"
                            label-for="start"
                        >
                            <span class="span-title">
                                {{ $t('trip_list.road_sec_from') }}
                            </span>
                            <b-form-datepicker
                                id="start"
                                v-model="dateFrom"
                                type="date"
                                size="sm"
                                format="dd-MM-yyyy"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                :placeholder="returnCurrentDate"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-4">
                        <b-form-group
                            class="nopads"
                            label-for="end"
                        >
                            <span class="span-title">
                                {{ $t('trip_list.road_sec_to') }}
                            </span>

                            <b-form-datepicker
                                id="end"
                                v-model="dateTo"
                                type="date"
                                size="sm"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                :placeholder="returnEndOfMonth"
                            />

                        </b-form-group>
                    </div>
                </b-row>
                <div class="button-container pr-3">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                        size="sm"
                    >
                        {{ $t('common.search') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <b-row class="pl-3">
            <div class="col-sm-6 pt-1 list-content">
                    <table v-if="allActiveLicenseUsers.length > 0 && searchResultsDone" class="table table-sm">
                        <tbody>
                            <tr>
                                <td class="item-detail-text strong">
                                    {{ $t('license_report.active_license_users') }} ({{selectedMonth}}/{{selectedYear}})
                                </td>
                                <td class="item-detail-text">
                                    {{ allActiveLicenseUsers.length }}
                                </td>
                            </tr>
                            <tr>
                                <td class="item-detail-text">
                                    {{ $t('license_report.total_users') }}.
                                </td>
                                <td class="item-detail-text">
                                    {{ this.totalUsers }} ({{ this.totalUsage }})
                                </td>
                            </tr>
                            <tr>
                                <td class="item-detail-text">
                                    {{ $t('license_report.total_roles') }}
                                </td>
                                <td class="item-detail-text">
                                    {{ this.totalRoles }}
                                </td>
                            </tr>
                            <tr>
                                <td class="item-detail-text">
                                    {{ $t('license_report.total_superusers') }}
                                </td>
                                <td class="item-detail-text">
                                    {{ this.totalSuperUsers }}
                                </td>
                            </tr>
                            <tr>
                                <td class="item-detail-text">
                                    {{ $t('license_report.total_billed_roles') }}
                                </td>
                                <td class="item-detail-text">
                                    {{ this.totalBilledRoles }} ({{ $t('license_report.observers') }}: {{ this.totalBilledObservers}}, {{ $t('license_report.workers') }}: {{ this.totalBilledWorkers}})
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>
            <div class="col-sm-6">
                    <b-button
                        variant="outline-success"
                        class="mr-4 result-button right"
                        @click="downloadLicenseReportExcel"
                        :disabled="results.length === 0"
                    >
                        {{ $t('common.download_excel') }}
                    </b-button>
            </div>
        </b-row>
        <transition name="fade">
            <license-summary-table
                :results="results"
                :is-admin="isAdmin"
                :allusers="users"
                :date-from="dateFromNum"
                :date-to="dateToNum"
            />
        </transition>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>
<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {userHelper} from '../mixins/UserMixin'
import {downloadHelper} from '../mixins/DownloadMixin'
import LicenseSummaryTable from './LicenseSummaryTable'
import {EventBus} from "@/event-bus";

export default {
    name: 'LicenseAdminView',
    components: {LicenseSummaryTable},
    mixins: [restApi, timeUtils, userHelper, downloadHelper],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            testresult: null,
            results: [],
            allActiveLicenseUsers: [],
            selectedMonth: null,
            selectedYear: null,
            company: null,
            editMode: false,
            loading: false,
            dateFrom: null,
            searchTimeStart: null,
            searchTimeEnd: null,
            searchResultsDone: false,
            test: null,
            users: [],
            dateTo: null,
            totalUsers: 0,
            totalUsage: 0,
            totalRoles: 0,
            totalSuperUsers: 0,
            totalBilledObservers: 0,
            totalBilledWorkers: 0,
            totalBilledRoles: 0,
            searchExists: false,
            searchParamsSave: null
        }
    },
    computed: {
        returnCurrentDate() {
            return new Date(this.getMonthStart()).toDateString()

        },
        returnEndOfMonth() {
            let today = new Date()
            return new Date(today.getFullYear(), today.getMonth()+1, 0).toDateString()
        },
        dateFromNum() {
            return new Date(this.dateFrom).getTime()+5*60*60*1000
        },
        dateToNum() {
            return new Date(this.dateTo).getTime()+5*60*60*1000
        }

    },
    mounted() {
        this.dateFrom = new Date(this.returnCurrentDate)
        this.dateTo = new Date(this.returnEndOfMonth)
    },
    methods: {
        onSubmit: function (){

            if (this.dateFromNum < this.dateToNum) {
                this.searchResultsDone = false
                this.totalUsers = 0
                this.totalUsage = 0
                this.totalRoles = 0
                this.totalSuperUsers = 0
                this.totalBilledRoles = 0
                this.totalBilledWorkers = 0
                this.totalBilledObservers = 0
                this.fetchCompanies()
                this.fetchActiveLicenses()
            }
            else{
                EventBus.$emit('show-alert', this.$t('license_report.search_error'))
            }

        },
        fetchCompanies: function () {
            let params = {}
            params.dateStart= this.dateFromNum
            params.dateEnd = this.dateToNum
            this.searchParamsSave = params
            this.searchExists = true
            this.loading = true
            this.restFetchParams(this.usersAndCompaniesURL, params, this.handleAllUsersAndCompanies)
        },
        handleAllUsersAndCompanies: function (response) {
            this.searchResultsDone = true
            this.testresult = response.data
            this.totalUsers = this.testresult[this.testresult.length-1].total_users
            this.totalUsage = this.testresult[this.testresult.length-1].supervisors_usage + this.testresult[this.testresult.length-1].workers_usage
            this.totalRoles = this.testresult[this.testresult.length-1].total_roles
            this.totalBilledRoles = this.testresult[this.testresult.length-1].total_billed_roles
            this.totalBilledObservers = this.testresult[this.testresult.length-1].total_billed_observers
            this.totalBilledWorkers = this.testresult[this.testresult.length-1].total_billed_workers
            this.totalSuperUsers = this.testresult[this.testresult.length-1].total_superusers
            this.loading = false
            this.results = this.testresult.slice(0, this.testresult.length-1)
        },
        async fetchActiveLicenses() {
            this.allActiveLicenseUsers = (await this.fetchAllLicenseReportUsers(this.dateFrom)).data;
            this.selectedMonth = new Date(this.dateFrom).getMonth() + 1;
            this.selectedYear = new Date(this.dateFrom).getFullYear();
        },
        downloadLicenseReportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.usersAndCompaniesURL + '/Excel',
                this.getCommonReportParams( this.searchParamsSave),
                this.downloadSuccess,
                this.downloadFail)
        },
        downloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('license_report.license_report_file_name'))
        },
        downloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        }
    }
}
</script>
