<template>
  <div class="col-sm-12 nopads">
    <div class="search-form">
      <div class="col-sm-12 search-form-title">
        <font-awesome-icon icon="search"/>
        {{ $t('common.search') }}
      </div>
      <b-form
          class="no-margin"
          @submit.prevent="onSubmit"
          @reset.prevent="onReset"
      >
        <b-row class="nopads">
          <div class="col-sm-6 pl-2 pr-2">
                        <span class="span-title">
                          {{ $t('ins_report.from_date') }}
                        </span>
            <datetime
                v-model="fromDate"
                type="datetime"
                format="dd.MM.yyyy HH:mm"
                class="routa-input"
                :placeholder="$t('observations_videos.search_input_format')"
            />
          </div>

          <div class="col-sm-6 pl-2 pr-2">
                        <span class="span-title">
                          {{ $t('ins_report.to_date') }}
                        </span>
            <datetime
                v-model="toDate"
                type="datetime"
                format="dd.MM.yyyy HH:mm"
                class="routa-input"
                :placeholder="$t('observations_videos.search_input_format')"
            />
          </div>
        </b-row>
        <b-row class="nopads pl-1 pr-1">
            <div class="col-sm-3 pl-2 pr-2">
                <div class="nopads">
                    <span class="span-title">{{ $t('observations.contract_collection') }}</span>
                    <b-form-group
                        class="title nopads"
                        label-for="contractCollection"
                    >
                        <b-form-select
                            id="contractCollection"
                            v-model="selectedContractCollection"
                            :disabled="!contractCollectionOptions || contractCollectionOptions.length === 0"
                            size="sm"
                        >
                            <template slot="first">
                                <option :value="null">
                                    {{ $t('trip_list.search_all') }}
                                </option>
                                <option
                                    v-for="item in contractCollectionOptions"
                                    :key="item.id"
                                    :value="item"
                                >
                                    {{ item.name }}
                                </option>
                            </template>
                        </b-form-select>

                    </b-form-group>
                </div>
            </div>
          <div class="col-sm-3 pl-1 pr-1" style="margin-top: -4px">
              <open-contract-filter
                  ref="contracts"
                  :to="toDate"
                  :from="fromDate"
                  :contract-collection="selectedContractCollection"
                  :show-company="false"
                  :showOnlyMyContractsOption="true"
                  :show-title="true"
                  :contract="preSelectedContract"
                  @loading="loading=true"
                  @loadingCompleted="loading=false"
                  @contractSelected="setContract"
                  @contractsFetched="setContractCollections"
              />
          </div>

            <div class="col-sm-3 pl-1 pr-1">
                <contract-order-filter
                    v-model="order"
                    :contract-id="contract ? contract.id : undefined"
                    :show-executor-text="true"
                />
            </div>

          <!-- Contractor -->
          <div class="col-sm-3 pl-1 pr-2">
            <span class="span-title">{{ $t('trip_list.search_with_company') }}</span>
            <multiselect
                v-model="companyParam"
                class="multiselect_routa"
                :placeholder="companyPlaceholder"
                :deselect-label="$t('common.remove_selection')"
                :select-label="$t('common.select')"
                :close-on-select="true"
                :disabled="!areaGroupsLoaded || companyOptions.length < 0"
                label="name"
                track-by="name"
                :searchable="true"
                :internal-search="true"
                :selected-label="$t('common.selected')"
                :options="companyOptions"
            >
              <template
                  slot="singleLabel"
                  slot-scope="props"
              >
                                <span class="option__desc">
                                  <span class="option__title">{{ props.option.name }}</span>
                                </span>
              </template>
              <template
                  slot="option"
                  slot-scope="props"
              >
                <div class="option__desc">
                  <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                </div>
              </template>
              <span slot="noResult">{{ $t('common.not_found') }}</span>
              <span slot="noOptions">{{ $t('common.no_results') }}</span>
            </multiselect>
          </div>
        </b-row>

          <!-- Search area -->
          <b-row class="nopads">
          <div class="col-sm-4 pl-2 pr-1" style="padding-top: .1em">
              <span class="span-title">{{ $t('trip_list.area') }}</span>
              <b-form-group

                  class="title nopads"
                  label-for="searchArea"
              >
                  <b-form-select
                      id="searchAreaInput"
                      v-model="selectedAreaGroup"
                      :disabled="!areaGroupsLoaded || mapSelectedArea"
                      :options="areaGroups"
                      size="sm"
                      class="no-margin"
                  >
                      <template slot="first">
                          <option :value="null">
                              {{ $t('trip_list.search_all') }}
                          </option>
                      </template>
                  </b-form-select>

              </b-form-group>

          </div>
          <div class="col-sm-5 pl-2">
              <span class="span-title">{{ $t('area_report.select_area') }}</span>
              <b-button
                  :disabled="isAreaSelected"
                  :variant="mapSelectedArea ? 'primary': 'info'"
                  size="sm"
                  style="margin-top: 1px"
                  class="result-button"
                  @click="openModalMap"
              >
                  {{ $t('trip_list.select_area_map') }}
              </b-button>
          </div>
        </b-row>

        <b-row class="nopads">
          <!-- Pricing model -->


            <div class="col-sm-4 pl-2 pr-1">
                <pricing-model-filter
                    v-model="pricingModel"
                />
            </div>

          <!-- User -->
          <div class="col-sm-4 pl-2 pr-1">
            <span class="span-title">{{ $t('trip_list.search_with_username') }}</span>
            <multiselect
                v-model="userParam"
                class="multiselect_routa"
                :placeholder="$t('work_time.select_person')"
                :deselect-label="$t('common.remove_selection')"
                :select-label="$t('common.select')"
                :close-on-select="true"
                label="name"
                track-by="name"
                :searchable="true"
                :internal-search="true"
                :selected-label="$t('common.selected')"
                :options="userOptions"
            >
              <template
                  slot="singleLabel"
                  slot-scope="props"
              >
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
              </template>
              <template
                  slot="option"
                  slot-scope="props"
              >
                <div class="option__desc">
                  <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                </div>
              </template>
              <span slot="noResult">{{ $t('common.not_found') }}</span>
              <span slot="noOptions">{{ $t('common.no_results') }}</span>
            </multiselect>
          </div>
        </b-row>
        <b-row>
          <!-- Vehicle -->
          <div class="col-sm-4 pl-2 pr-2">
            <span class="span-title">{{ $t('trip_list.vehicle') }}</span>
            <multiselect
                v-model="vehicle"
                class="multiselect_routa"
                :placeholder="$t('trip_list.select_vehicle')"
                :deselect-label="$t('common.remove_selection')"
                :select-label="$t('common.select')"
                :close-on-select="true"
                label="name"
                track-by="name"
                :searchable="true"
                :internal-search="true"
                :selected-label="$t('common.selected')"
                :options="vehicleOptions"
            >
              <template
                  slot="singleLabel"
                  slot-scope="props"
              >
                              <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                              </span>
              </template>
              <template
                  slot="option"
                  slot-scope="props"
              >
                <div class="option__desc">
                  <span class="option__title multiselect_detail_block">{{
                      props.option.name
                    }}</span>
                </div>
              </template>
              <span slot="noResult">{{ $t('common.not_found') }}</span>
              <span slot="noOptions">{{ $t('common.no_results') }}</span>
            </multiselect>
          </div>
          <div class="col-sm-4 pl-1 pr-2">
            <span class="span-title">{{ $t('trip_list.search_with_license') }}</span>
            <b-form-group
                class="title nopads"
            >
              <b-form-input
                  id="licensePlate"
                  v-model="licensePlate"
                  type="text"
                  size="sm"
                  class="no-margin"
                  :disabled="!contract"
                  :placeholder="$t('trip_list.search_with_license')"
              />
            </b-form-group>
          </div>
        </b-row>
        <b-row class="nopads">
          <div class="col-sm-4 pl-2 pr-1">
            <span class="span-title">{{ $t('trip_list.search_with_road_name') }}</span>
            <b-form-group
                class="title nopads"
                label-for="roadName"
            >
              <b-form-input
                  id="roadNameInput"
                  v-model="roadName"
                  type="text"
                  size="sm"
                  class="no-margin"
                  :placeholder="$t('trip_list.search_with_road_name')"
              />
            </b-form-group>
          </div>
          <div class="col-sm-4 pl-2 pr-1">
            <span class="span-title">{{ $t('trip_list.search_with_road_num') }}</span>
            <b-form-group
                class="title nopads"
            >
              <b-form-input
                  id="roadInput"
                  v-model="roadNumber"
                  type="number"
                  size="sm"
                  class="no-margin"
                  :placeholder="$t('trip_list.search_with_road_num')"
              />
            </b-form-group>
          </div>
          <div class="col-sm-2 pl-2 pr-1">
            <span class="span-title">{{ $t('trip_list.search_with_road_sec') }}</span>
            <b-form-group
                class="title nopads"
                label-for="roadSecFrom"
            >
              <b-form-input
                  id="roadSecFrom"
                  v-model="roadSecFrom"
                  type="number"
                  size="sm"
                  class="no-margin"
                  :placeholder="$t('trip_list.road_sec_from')"
              />
            </b-form-group>
          </div>
          <div class="col-sm-2">
            <span class="span-title">{{ $t('trip_list.search_with_road_sec') }}</span>
            <b-form-group
                class="title nopads"
                label-for="roadSecTo"
            >
              <b-form-input
                  id="roadSecTo"
                  v-model="roadSecTo"
                  type="number"
                  size="sm"
                  class="no-margin"
                  :placeholder="$t('trip_list.road_sec_to')"
              />
            </b-form-group>

          </div>


        </b-row>
        <div class="col-sm-12 button-container">
          <b-button
              type="submit"
              variant="primary"
              class="result-button"
          >
            {{ $t('ins_report.search') }}
          </b-button>

            <b-button
              type="reset"
              variant="danger"
              class="result-button"
          >
            {{ $t('ins_report.clear') }}
          </b-button>


        </div>

      </b-form>

    </div>
      <!--select area on map modal-->
      <base-modal v-if="showMapModal" style="z-index: 50">

          <div class="details-container">
              <div
                  class="details dynamic_width"
              >
                  <div class="coordinate-map"
                       style="height:80vh;"
                  >

                      <map-container
                          ref="map"
                          :find-user="true"
                          :is-editing="isEditing"
                          :center="mapCenter"
                          @onMapClicked="mapClicked"
                          @editAreaEvent="editAreaEvent"
                          :menu-enabled="false"
                      />
                  </div>
                  <div class="col-sm-12 button-container pt-2 pb-0 mb-0">
                      <b-button
                          @click.stop="clearPolygon"
                          variant="danger"
                          class="result-button"
                      >
                          {{ $t('ins_report.clear') }}
                      </b-button>
                      <b-button
                          variant="primary"
                          class="result-button"
                          @click.stop="backFromModal"
                      >
                          {{ $t('common.back') }}
                      </b-button>
                      <b-button
                          variant="success"
                          class="result-button"
                          @click.stop="closeModalMap"
                      >
                          {{ $t('common.save') }}
                      </b-button>
                  </div>
              </div>
          </div>
          <div
              v-if="loading"
              id="loader"
              class="spinner"
          />

      </base-modal>

    <div
        v-if="loading"
        id="loader"
        class="spinner"
    />
  </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import Multiselect from 'vue-multiselect'
import {timeUtils} from '../mixins/TimeUtils'
import {orderMixin} from "../mixins/OrderMixin";
import PricingModelFilter from "../pricingmodels/PricingModelFilter";
import {areaHelper} from "@/components/mixins/AreaMixin";
import {contractHelper} from "@/components/mixins/ContractMixin";
import MapContainer from "@/components/map/MapContainer.vue";
import BaseModal from "@/components/BaseModal.vue";
import OpenContractFilter from "@/components/contract/OpenContractFilter.vue";
import ContractOrderFilter from "@/components/order/ContractOrderFilter.vue";
import {mapHelper} from "@/components/mixins/MapMixin";

export default {
  name: 'TripSearch',
  components: {
      ContractOrderFilter,
      OpenContractFilter,
      BaseModal, MapContainer, PricingModelFilter,
      Multiselect},
  mixins: [restApi, timeUtils, orderMixin, areaHelper, contractHelper, mapHelper],
  props: {
    user: {
      type: Object,
      default: null
    },
    equipment: {
      type: Object,
      default: null
    },
    isAdmin: Boolean,
    preSelectedContract: {
        type: Object,
        default: null
    }
  },
  data: function () {
    return {
      advancedSearch: false,
      areaGroupObjects: [],
      areaGroups: [],
      selectedAreaGroup: null,
      userParam: null,
      companyParam: null,
      vehicle: null,
      fromDate: '',
      toDate: '',
      contract: null,
      customer: null,
      contractor: null,
      pricingModel: null,
      order: null,
      licensePlate: '',
      roadSecFrom: '',
      roadSecTo: '',
      roadNumber: '',
      roadName: '',
      userOptions: [],
      companyOptions: [],
      vehicleOptions: [],
      contractCollectionOptions: [],
      orders: [],
      results: [],
      usersLoaded: false,
      areaGroupsLoaded: false,
      vehiclesLoaded: false,
      loading: false,
      companyPlaceholder: '',
      selectedAreaTypes: [],
      selectedContractCollection: null,
      newPolygonId: -1,
      equipmentUpdate: this.jsonToEquipment(this.equipment),
      mapCenter: null,
      isEditing: false,
      newAreaName: null,
      showMapModal: false,
      mapDrawnAreas: null,
      mapSelectedArea: this.updateSelected,
      areaSelected: false,
      drawnAreas: null,
      areaCleared: false,
    }
  },
    computed: {
        updateSelected: function() {
            return this.mapDrawnAreas === null ? false : this.mapDrawnAreas.length >= 4;
        },

        isAreaSelected: function () {
            return this.selectedAreaGroup !== null
        }
    },

  watch: {
      mapDrawnAreas: function () {
        this.mapSelectedArea = this.updateSelected;
      }
  },
  mounted: function () {
      this.loading = true
      if (!this.fromDate) {
        this.fromDate = this.preSelectedContract ? this.getDayStart() : this.getMonthStart()
      }
      this.getUsers()
      this.getAreaGroups()
      this.getVehicles()
      this.enablePolygonDrawing()
      this.companyPlaceholder = this.$t('trip_list.search_select_contract_first')
  },
  methods: {

      backFromModal() {
        this.showMapModal = false
        this.areaCleared = false;
        if (this.mapDrawnAreas === null) {
            this.newPolygonId = -1
        }
      },

      closeModalMap() {
          if (this.areaCleared) {
              this.areaCleared = false;
              this.enablePolygonDrawing()
          }
          this.mapDrawnAreas = this.getPolygon();
          if (this.mapDrawnAreas === null) {
              this.newPolygonId = -1
          }
          this.showMapModal = false;
          this.$emit('areaGroupSelected', this.getSelectedAreaGroup())
          this.$emit('areaSelect', this.mapDrawnAreas)

      },

      disableAreaEditing() {
          if (this.isEditing) {
              this.isEditing = false
              this.polygonDrawing = true
              this.editingAreaEventFlag = false
              this.$refs.map.getMap().removeAnchorPoints()
          }
      },

      openModalMap() {
          // Init map center and open map
          if(this.getMapSettingsIn(['center'])){
              this.mapCenter = this.getMapSettingsIn(['center'])
          } else if (this.user && this.user.company.location) {
              this.mapCenter = {
                  lon: this.user.company.location.x,
                  lat: this.user.company.location.y,
              }
          } else if (this.center) {
              this.mapCenter = this.center
          }
          this.showMapModal = true;
          this.redrawArea()
      },


      redrawArea: function () {
          this.$nextTick(() => {
              this.drawAreas(this.mapDrawnAreas)
          })

      },

      editingPolygon: function () {
          this.$refs.map.getMap().editArea(this.newPolygonId)
          this.isEditing = true
          this.polygonDrawing = false
      },

      drawAreas: function (areas) {
          if (this.newPolygonId !== -1) {
              this.newPolygonId = -1
              areas.forEach(area => {
                  const poly = {lng: area[0], lat: area[1]}
                  this.addPointToPolygon(poly)
              })
              this.editingPolygon()
          }
          else {
              this.disableAreaEditing()
          }

      },



    mapClicked: function (coord) {
      if (this.polygonDrawing && !this.isEditing) {
        this.addPointToPolygon(coord)
      }
    },


    editAreaEvent: function (id) {
        //This allows us to store the id of the polygon which is in focus
        //i.e. it was clicked on by the user
        if (!this.polygonDrawing) {
            this.editingAreaEventFlag = true
            this.newPolygonId = id
        }
    },

    clearPolygon: function () {
      if (this.newPolygonId > 0) {
          this.$refs.map.getMap().removePolygon(this.newPolygonId, this.OTHER)
      }
      this.disableAreaEditing()
      this.areaCleared = true;
    },

    addPointToPolygon: function (point) {
      // If not exist yet, create one and add to map
      if (this.newPolygonId < 0) {
        this.newPolygonId = Date.now()
        this.$refs.map.getMap().newPolygon(this.newPolygonId, point.lat, point.lng, this.OTHER)
      } else {
        this.$refs.map.getMap().addPointToPolygon(this.newPolygonId, point.lat, point.lng, this.OTHER)
      }
    },

    enablePolygonDrawing: function () {
        this.newPolygonId = -1
        this.newAreaName = null
        this.newAreaSqm = null
        this.selectedAreaType = null
        this.polygonDrawing = true
        this.polygonDrawing = true
    },

    getSelectedAreaGroup: function () {
      if (this.selectedAreaGroup) {
        return this.areaGroupObjects.find(item => item.id === this.selectedAreaGroup)
      }
      return null
    },

      setContract: function (contract) {
          this.contract = contract
          this.getContractCompanies()
      },

    onReset: function () {
      this.roadNumber = ''
      this.roadName = ''
      this.roadSecFrom = ''
      this.roadSecTo = ''
      this.contract = null
      this.selectedContractCollection = null
      this.userParam = null
      this.pricingModel = null
      this.fromDate = this.getMonthStart()
      this.toDate = ''
      this.order = null
      this.companyParam = null
      this.selectedAreaGroup = null
      this.vehicle = null
      this.licensePlate = ''
      this.enablePolygonDrawing()
      this.mapDrawnAreas = null;
      this.$refs.contracts.resetSelection()
    },
    // Get users for dropdown menu
    getUsers: function () {
      this.restFetch(this.tripUserUrl, this.handleUsers)
    },

    handleUsers: function (response) {
      if (response && response.data) {
        this.userOptions = response.data.map(function (user) {
          return {value: user.id, name: user.last_name + ', ' + user.first_name}
        })
      }
      this.usersLoaded = true
      this.loading = this.isLoading()
    },

    // Get vehicles for dropdown menu
    getVehicles: function () {
      this.restFetch(this.vehicleUrl, this.handleVehicles)
    },

    handleVehicles: function (response) {
      if (response && response.data) {
        this.vehicleOptions = response.data.map(function (item) {
          return {
            value: item.id,
            name: item.make + ' ' + item.vehicle_model + ' - ' + item.license_plate
          }
        })
      }
      this.vehiclesLoaded = true
      this.loading = this.isLoading()
    },

    isLoading: function () {
      return !this.usersLoaded || !this.areaGroupsLoaded || !this.vehiclesLoaded
    },

    getContractCompanies: function () {
      this.companyOptions = []
      this.companyParam = null
      this.companyPlaceholder = this.$t('trip_list.search_select_contract_first')
      if (this.contract) {
          this.restFetchParams(this.contractCompanyUrl, {contract: this.contract.id}, this.handleContractCompanies)
      }
    },

    handleContractCompanies: function (response) {
      if (response && response.data) {
        this.companyPlaceholder = this.$t('')
        response.data.forEach(company => {
          this.companyOptions.push({name: company.name, value: company.id})
        })
        this.companyPlaceholder = this.$t('users.select_company')
      }
      this.loading = false
    },

    getAreaGroups: function () {
      this.loading = true
      this.restFetchParams(this.areaGroupUrl, {no_geom: 1}, this.handleAreaGroupFetch)
    },

    handleAreaGroupFetch: function (response) {
      if (response.data) {
        this.areaGroupObjects = response.data
        response.data.forEach(areaGroup => {
          this.areaGroups.push({text: areaGroup.name, value: areaGroup.id})
        })
      }
      this.areaGroupsLoaded = true
      this.loading = this.isLoading()
    },

      getPolygon: function () {
          if (this.newPolygonId === -1) {
              return null
          }
          let original = this.$refs.map.getMap().getPolygonBoundaries(this.newPolygonId, this.OTHER)
          // Outer ring is enough, but close polyohn
          let result = original[0].map(item => [item['lng'], item['lat']])
          result.push([original[0][0].lng, original[0][0].lat])
          return result
      },

    onSubmit: function () {

        var params = {}
        if (this.selectedAreaGroup) {
            this.$emit('areaGroupSelected', this.getSelectedAreaGroup())
            params.area_group = this.selectedAreaGroup
        }
        if (this.roadNumber > 0) {
            params.road_num = this.roadNumber
        }
        if (this.roadName.trim()) {
            params.road_name = this.roadName
        }
        if (this.licensePlate.trim()) {
            params.license_plate = this.licensePlate
        }
        if (this.roadSecFrom > 0) {
            params.road_sec_from = this.roadSecFrom
        }
        if (this.roadSecTo > 0) {
            params.road_sec_to = this.roadSecTo
        }
        if (this.contract) {
            params.contract = this.contract.id
            params.contractor = this.contract.contractor.id
            params.customer = this.contract.customer.id
        }
        if (this.order) {
            params.order = this.order.id
        }
        if (this.pricingModel) {
            params.pricing_model = this.pricingModel.id
        }
        if (this.userParam) {
            params.user = this.userParam.value
        }
        if (this.fromDate) {
            params.from = this.fromDate
        }
        if (this.toDate) {
            params.to = this.toDate
        }
        if (this.companyParam && this.companyParam.value) {
            params.company = this.companyParam.value
        }
        if (this.vehicle) {
            params.vehicle = this.vehicle.value
        }
        if (this.newPolygonId > 0) {
            const polygonObj = this.mapDrawnAreas;
            if (polygonObj !== null && polygonObj.length >= 4) {
                params.polygon_search = "POLYGON((" + polygonObj.map(ob => ob.join(' ')) + "))";
            }
        }

        params.trip_notes = 1
          this.$emit('search', params)
        }
    }
}
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
