<template>
    <div>
        <h3>{{ $t('favorites.add_favorite')}}</h3>
        <open-contract-filter
            :from="now"
            :show-title="true"
            :show-company="false"
            :apply-padding="false"
            @contractSelected="setContract"/>
        <div class="col-sm-12 text-right mt-2">
            <b-button
                variant="secondary"
                class="form-button"
                @click.stop="$emit('close')"
            >
                {{ $t('common.cancel') }}
            </b-button>
            <b-button
                variant="success"
                class="form-button"
                :disabled="!this.id"
                @click.stop="save"
            >
                <span>
                  {{ $t('common.save') }}
                </span>
            </b-button>
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import OpenContractFilter from "@/components/contract/OpenContractFilter";

export default {
    name: 'FavoritesSelector',
    components: {OpenContractFilter},
    mixins: [restApi],
    data: function () {
        return {
            type: 1,  // Contract
            id: null
        }
    },
    computed: {
        now() {
            return new Date().toISOString();
        },
    },
    methods: {
        setContract(contract) {
            this.id = contract.id
        },

        save() {
            this.$emit('newFavorite', this.type, this.id)
        }
    }
}
</script>
