<template>
    <div class="w-100" v-if="!drawAll && !isEditing">
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
            v-bind:class="{ list_closed: !listOpen }"
        >
            <span
                class="vehicle-list-title"
                @click.stop="toggleList">
                {{ $t('areas.area_types_title') }} ({{ typeEntries ? typeEntries.length : 0 }})
            </span>
            <div
                class="vehicle-list-caret"
                v-bind:class="{ caret_open: listOpen }"
                @click.stop="toggleList">
                <font-awesome-icon icon="caret-down"/>
            </div>

            <div
                class="col-sm-12 nopads map-accordion-content"
                v-bind:class="{ 'map-accordion-open': listOpen }"
            >
                <!-- History road type selection -->
                <div v-if="!hideBadges && !contractSelected" class="badge-filters__wrapper pl-2 pr-2">
                    <div class="badge-filters">
                        <b-badge
                            @click="areaCollection = 'own'"
                            :variant="areaCollection === 'own' ? 'primary' : 'light'"
                            style="padding: .5em 1em"
                        >
                            {{ $t('areas.fetch_own_areas') }}
                        </b-badge>
                        <b-badge
                            @click="areaCollection = 'contract'"
                            :variant="areaCollection === 'contract' ? 'primary' : 'light'"
                            style="padding: .5em 1em"
                        >
                            {{ $t('areas.fetch_contract_areas') }}
                        </b-badge>
                    </div>
                </div>
                <div
                    class="map-select-item"
                >
                    <b-form-checkbox
                        v-model="allSelected"
                        name="checkboxAllTypes"
                        class="text-light"
                        @change="toggleAll"
                    >
                        {{ allSelected ? $t('areas.deselect_all') : $t('areas.select_all') }}
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-if="!hideNameToggle"
                        v-model="showLabels"
                        name="checkboxAllTypes"
                        class="text-light mt-1"
                        @change="$emit('areaLabelsChanged', showLabels)"
                    >
                        {{ $t('areas.show_labels') }}
                    </b-form-checkbox>
                </div>
                <div
                    class="map-select-item"
                >
                    <!-- Check box for select/ unselect types -->
                    <span class="text-light">{{ $t('areas.type_select_hint') }}</span>
                    <b-form-group>
                        <b-form-checkbox
                            v-for="option in typeEntries"
                            v-model="selectedTypes"
                            :key="option.value"
                            :value="option.value"
                            class="text-light pt-2"
                        >
                            {{ option.text }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </div>
        </div>
    </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import {mapHelper} from "@/components/mixins/MapMixin";

export default {
    name: 'MapAreaSelector',
    mixins: [restApi, mapHelper],
    props: {
        contractSelected: {
            type: Boolean,
            default: false
        },
        hideBadges: {
            type: Boolean,
            default: false
        },
        hideNameToggle: {
            type: Boolean,
            default: false
        },
        contracts: {
            type: Array,
            default() {
                return []
            }
        },
        fetchEquipments: {
            type: Boolean,
            default: true
        },
        drawAll: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            areaTypes: [],
            loading: false,
            typeEntries: [],
            selectedTypes: [],
            listOpen: false,
            allSelected: false,
            areaCollection: null,
            showLabels: false,
            results: [],
            areaResults: [],
        }
    },
    watch: {
        areaResults: function () {
            this.initView()
        },
        contracts(newValue, oldValue) {
            if (newValue.length + oldValue.length > 0) {
                this.fetchAreaTypes()
            }
        },
        selectedTypes: function () {
            this.$emit("areaSelectionChanged", this.selectedTypes, this.areaResults)
        },
        areaCollection: function (value) {
            this.updateMapSettings({ buttons: { areas: { 'type': value } } });
            this.saveMapSettings();
            this.$emit('areaCollectionChanged', value)
        }
    },
    mounted: function () {
        this.areaCollection = this.getMapSettingsIn(['buttons', 'areas', 'type']);
        if (!this.areaCollection) {
            this.areaCollection = 'own'
        }
    },
    methods: {
        initView: function () {
            this.initTypeEntries()
        },

        initTypeEntries: function () {
            //Make sure the type list is empty
            this.typeEntries = []
            //Check that there are some areas to select from
            if (this.areaResults && this.areaResults.length > 0) {
                //Find all the different types that exist in this data
                this.areaResults.forEach(item => {
                    let type = item.type
                    let existingType = this.typeEntries.find(element => element.value === type.id)
                    if (!existingType) {
                        this.typeEntries.push({text: type.name, value: type.id})
                    }
                })
                //Sort the found types
                this.sortTypeEntries()
                if (this.allSelected || this.drawAll) {
                    this.toggleAll(true)
                }
            }
        },

        sortTypeEntries: function () {
            this.typeEntries.sort(function (a, b) {
                if (a.text < b.text) {
                    return -1;
                }
                if (a.text > b.text) {
                    return 1;
                }
                return 0;
            });
        },

        toggleList: function () {
            this.listOpen = !this.listOpen
        },

        toggleAll(checked) {
            this.selectedTypes = []
            if (checked) {
                this.typeEntries.forEach(item => {
                    this.selectedTypes.push(item.value)
                })
            }
        },

        fetchAreaTypes: function () {
            this.loading = true
            this.restFetch(this.areaTypeUrl, this.handleAreaTypesResponse)
        },

        handleAreaTypesResponse: function (response) {
            this.loading = false
            this.areaTypes = []
            this.areaTypes.push({value: null, text: this.$t('areas.select_area_type')})
            response.data.forEach(type => {
                this.areaTypes.push({value: type, text: type.name})
            })
            // Next fetch areas and show according to filter
            this.fetchAreas()
        },

        fetchAreas: function () {
            this.loading = true
            const params = {}
            params.equipment = this.fetchEquipments
            if (this.contracts) {
                params.contractIds = this.contracts
            }
            this.restFetchParams(this.areaUrl, params, this.handleAreaResults)
        },

        handleAreaResults: function (response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
                this.areaResults = this.results;
                if (this.areaResults.length === 0) {
                    this.$emit("areaSelectionChanged", this.selectedTypes, this.areaResults)
                }
                this.$emit("updateTypes", this.areaTypes, this.areaResults)
            }
        },




        fetchAreasBy() {
            let type
            if(this.selectedContractIds && this.selectedContractIds.length > 0 || !!this.selectedContractId) {
                type = 'contract'
            } else {
                type = this.areaCollection
            }
            if (type) {
                if (type === "own") {
                    this.fetchOwnAreas();
                }
                else if (type === "contract") {
                    this.fetchContractAreas();
                }
            }
        },

        fetchOwnAreas: function () {
            this.loading = true
            let params = {equipment: false}
            if(this.company) {
                params.company = this.company
            }
            if(this.selectedContractIds) {
                params.contract = this.selectedContractIds
            }
            if(this.selectedContractIds && this.selectedContractIds.length > 0) {
                // fetch by area-group when contract is selected
                this.restFetchParams(this.areaGroupUrl, params, this.handleGroupResults)
            } else {
                this.restFetchParams(this.areaUrl, params, this.handleResults)
            }
        },

        handleResults: function (response) {
            if (response && response.data) {
                this.results = response.data
                this.areaResults = this.results;
                if (this.areaResults.length === 0) {
                    this.$emit("areaSelectionChanged", this.selectedTypes, this.areaResults)
                }
                // Send the areas to the parent for selection purposes
            }
            this.loading = false
        },

        fetchContractAreas: function () {
            let params = {equipment: false}
            this.loading = true
            if(this.company) {
                params.company = this.company
            }
            if(this.selectedContractIds && this.selectedContractIds.length > 0) {
                params.contract = this.selectedContractIds
            } else if(this.selectedContractId) {
                params.contract = [this.selectedContractId]
            }
            this.results = []
            this.restFetchParams(this.areaGroupUrl, params, this.handleGroupResults)
        },

        handleGroupResults: function (response) {
            if (response && response.data) {
                // Loop through area groups areas and add the to results
                let areaGroups = response.data
                this.results = []
                areaGroups.forEach(item => {
                    item.areas.forEach(area => {
                        if (!this.results.find(item => item.id === area.id)) {
                            this.results.push(area)
                        }
                    })
                });
                this.areaResults = this.results;
                if (this.areaResults.length === 0) {
                    this.$emit("areaSelectionChanged", this.selectedTypes, this.areaResults)
                }
            }
            this.loading = false
        }

    }
}
</script>
