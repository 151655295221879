export const materialReportHelper = {
    methods: {
        getSimpleMaterialReport() {
            return {
                id: -1,
                amount: null,
                contract: null,
                work_order: null,
                reporting_date: null,
                material: null,
                unit: null,
                info: null,
                consumption_records: []
            }
        }
    }
}
