<template>
    <div
        class="details-container"
    >
        <div
            class="details dynamic_width nopads"
        >
            <div
                class="trip-results"
                style="height:70vh !important;"
            >
                <!-- Map for showing trip here -->
                <map-container
                    ref="mapContainer"
                    :find-user="false"
                    @onDragEnd="dragEnd"
                    @onMapChanged="mapChanged"
                />
                <trip-map
                    v-if="map && mapTrip"
                    ref="tripMap"
                    :trip-item="mapTrip"
                    :map="map"
                    :show-end-points="selectedReportType === 5"
                    @routePartSelected="onRouteSelected"
                />
            </div>
            <div v-if="isMaterialReport">
            <div v-for="(item, index) in materialReports" :key="index">
                <b-container fluid="sm">
                    <b-row>
                        <b-col cols="10">
                            <b-row>
                                <span class="span-title">
                                    {{ $t('spreader_data.dry_material') }}
                                </span>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    <span class="search-title ">{{ $t('spreader_data.materials') }}</span>
                                    <spreader-material-selector
                                        :materialType="materialTypes.DRY"
                                        @onMaterialSelection="value => item.materialReportDryMaterial = value ? value.material : value"
                                    />
                                </b-col>
                                <b-col cols="3">
                                    <span class="search-title ">{{ $t('spreader_data.amountWithoutUnit') }}</span>
                                    <b-form-input
                                        id="amount"
                                        v-model="item.materialReportDryAmount"
                                        size="sm"
                                        type="number"
                                        class="no-margin"
                                    />
                                </b-col>
                                <b-col cols="3">
                                    <span class="search-title ">{{ $t('spreader_data.unit') }}</span>
                                    <b-form-select
                                        id="unit"
                                        v-model="item.materialReportDryUnit"
                                        size="sm"
                                        class="no-margin"
                                        :options="options">

                                    </b-form-select>
                                </b-col>
                            </b-row>
                            <b-row class="mt-1">
                                <span class="span-title">
                                    {{ $t('spreader_data.liquid_material') }}
                                </span>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    <span class="search-title ">{{ $t('spreader_data.materials') }}</span>
                                    <spreader-material-selector
                                        :materialType="materialTypes.LIQUID"
                                        @onMaterialSelection="value => item.materialReportLiquidMaterial = value ? value.material : value"
                                    />
                                </b-col>
                                <b-col cols="3">
                                    <span class="search-title ">{{ $t('spreader_data.amountWithoutUnit') }}</span>
                                    <b-form-input
                                        id="amount"
                                        v-model="item.materialReportLiquidAmount"
                                        size="sm"
                                        type="number"
                                        class="no-margin"
                                    />
                                </b-col>
                                <b-col cols="3">
                                    <span class="search-title ">{{ $t('spreader_data.unit') }}</span>
                                    <b-form-select
                                        id="unit"
                                        v-model="item.materialReportLiquidUnit"
                                        size="sm"
                                        class="no-margin"
                                        :options="options">

                                    </b-form-select>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="2" class="d-flex">
                                <span class="d-inline-block mt-auto mb-auto">
                                    <b-button
                                        variant="success"
                                        size="sm"
                                        class="pt-1 pb-1 mx-1"
                                        @click.stop="addMaterialReport"
                                    >
                                        <i class="fa fa-plus" style="font-size: .8em; padding: 0; line-height: 1em;"/>
                                    </b-button>
                                </span>
                                <span class="d-inline-block mt-auto mb-auto">
                                    <b-button
                                        variant="danger"
                                        size="sm"
                                        class="pt-1 pb-1 mx-1"
                                        :disabled="materialReports.length <= 1"
                                        @click.stop="removeMaterialReport(index)"
                                    >
                                        <i class="fa fa-trash" style="font-size: .8em; padding: 0; line-height: 1em;"/>
                                    </b-button>
                                </span>
                        </b-col>
                    </b-row>
                </b-container>
                <hr v-if="index < (materialReports.length - 1)"/>
            </div>
        </div>

        <div class="col-12 button-container">
            <b-button class="form-button" size="sm" variant="secondary" @click="closeModal">
                {{ $t('common.close') }}
            </b-button>
            <b-button v-if="isMaterialReport"  class="form-button" size="sm" variant="success" @click="sendMaterialReports">
                {{ $t('common.add') }}
            </b-button>
        </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        ></div>
    </div>
</template>

<script>
import MapContainer from "@/components/map/MapContainer.vue";
import TripMap from "@/components/trip/TripMap.vue";
import {contractReportTypes} from "@/components/mixins/ContractMixin";
import SpreaderMaterialSelector from "@/components/materials/spreaderdata/SpreaderMaterialSelector.vue";
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import {EventBus} from '@/event-bus'

export default {
    name: "ContractModal",
    components: {SpreaderMaterialSelector, TripMap, MapContainer},
    mixins: [restApi, spreaderDataMixin],
    props: {
        mapVisible: {
            type: Boolean,
            default: false
        },
        mapTripId: {
            type: Number,
            default: null
        },
        selectedReportType: {
            type: Number,
            default: null
        },
        results: {
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            materialReports: [],
            map: null,
            mapTrip: null,
            selectedRoutePoints: null,
            modalVisible: false,
            data: {},
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            variant: 'danger',
            alertMessage: ''
        }
    },
    computed: {
        isMaterialReport() {
            return this.selectedReportType === contractReportTypes.REPORT_MATERIAL
        },
        options() {
            return [
                {value: 'kg', text: this.$t('unit.kg')},
                {value: 't', text: this.$t('unit.tons')},
                {value: 'm3', text: this.$t('unit.cubic_m')},
                {value: 'l', text: this.$t('unit.liter')},
            ]
        }
    },

    watch: {
        modalVisible() {
            this.$emit("modalVisible", this.modalVisible);
        }
    },
    mounted() {
        this.addMaterialReport()
        this.$nextTick(() => {
            this.map = this.$refs.mapContainer.getMap()
            this.map.resizeMap()
            this.mapTrip = this.results.find(item => item.id === this.mapTripId)
            this.selectedRoutePoints = this.mapTrip.geometry
        })
    },

    methods: {

        onRouteSelected(route) {
            this.selectedRoutePoints = route
        },

        dragEnd(data) {
            if (this.$refs.tripMap) {
                this.$refs.tripMap.onDragEnd(data)
            }
        },

        sendMaterialReports() {
            this.loading = true
            let trip = JSON.parse(JSON.stringify(this.mapTrip))
            trip.geometry = this.selectedRoutePoints
            const data = {
                materialReports: this.materialReports,
                trip: trip
            }
            this.restAdd(this.spreaderDataAddToRoutaUrl, data, () => {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('spreader_data.alert_materials_add_success'), 'success')
            this.resetMaterialReports()
            this.modalVisible = false
            }, () => {
                this.loading = false
                this.showAlert(this.$t('spreader_data.alert_materials_add_fail'))
            })
        },

        closeModal() {
            this.$emit('close')
            this.resetMaterialReports()
        },

        resetMaterialReports() {
            this.materialReports = []
            this.addMaterialReport()
        },

        addMaterialReport() {
            const material = this.createNewMaterialReport(null, 0, null, null, 0, null)
            this.materialReports.push(material)
        },

        removeMaterialReport(index) {
            this.materialReports.splice(index, 1)
        },

        createNewMaterialReport(materialDry, dryAmount, dryUnit, materialLiquid, liquidAmount, liquidUnit) {
            return {
                materialReportDryMaterial: materialDry,
                materialReportDryAmount: dryAmount,
                materialReportDryUnit: dryUnit,
                materialReportLiquidMaterial: materialLiquid,
                materialReportLiquidAmount: liquidAmount,
                materialReportLiquidUnit: liquidUnit
            }
        },

        mapChanged(map) {
            this.map = map
        },
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
    }
}
</script>

<style scoped>
.nooverflow {
    overflow: hidden;
}
</style>
