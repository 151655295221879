<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <div class="report" v-if="Object.keys(this.reportDetails).length !== 0">

    <!-- Basic info -->
    <span class="span-header">
      {{ $t('ins_report_details.title') }}
    </span>
        <table class="table table-sm table-fixed">
            <tbody>
            <tr>
                <td class="item-detail-title">
                    {{ $t('ins_report_details.date') }}
                </td>
                <td class="item-detail-text">
                    {{ toLocalTime(reportDetails.created) }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('ins_report.list_label.report_type') }}
                </td>
                <td class="item-detail-text">
                    {{ getInspectionTypeName(reportDetails.type) }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('ins_report.list_label.name') }}
                </td>
                <td class="item-detail-text">
                    {{ reportDetails.name }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('inspection_editor.inspection_frequency') }}
                </td>
                <td class="item-detail-text">
                    {{ getInspectionFrequency(reportDetails.frequency) }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('ins_report_details.inspector') }}
                </td>
                <td class="item-detail-text">
                    {{ getInspector(reportDetails.reporter) }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('ins_report.info') }}
                </td>
                <td class="item-detail-text">
                    {{ reportDetails.info }}
                </td>
            </tr>
            <tr>
                <td class="item-detail-title">
                    {{ $t('weather_station.location') }}
                </td>
                <td class="item-detail-text">
                    {{ reportDetails.location === undefined ? $t('inspection.unknown_location') : reportDetails.location.x + " " + reportDetails.location.y }}
                </td>
            </tr>
            </tbody>
        </table>

        <!-- Inspector qualifications/ certificates -->
        <div  v-if="reportDetails !== undefined && reportDetails.inspected_company || reportDetails !== undefined && reportDetails.reporter.certificates" class="col-12 nopads">
            <span class="span-header">
              {{ $t('ins_report_details.reporter_certificates') }}
            </span>
            <inspector-certificates v-if="reportDetails.reporter.certificates.length > 0"
                                    :certificates="reportDetails.reporter.certificates"/>
            <span v-else>-</span>
        </div>

        <!-- Target information -->
        <span class="span-header">
      {{ $t('ins_report_details.target') }}
    </span>
        <target-info-vehicles v-if="reportDetails.vehicle" :vehicle="reportDetails.vehicle"/>
        <target-info-contract v-if="reportDetails.contract" :contract="reportDetails.contract"/>
        <target-info-order v-if="reportDetails.order" :order="reportDetails.order"/>
        <target-info-company v-if="reportDetails.inspected_company" :company="reportDetails.inspected_company"/>
        <target-info-bridge v-if="reportDetails.bridge_id" :bridge-id="reportDetails.bridge_id"/>
        <target-info-culvert v-if="reportDetails.culvert_id" :culvert-id="reportDetails.culvert_id"/>

        <!--Inspection results-->
        <span class="span-header">
      {{ $t('ins_report_details.ins_results') }}
    </span>
        <div
            v-if="reportDetails.results && reportDetails.results.length"
            class="col-sm-12 panel-group data-container"
        >
            <b-table
                show-empty
                small
                stacked="md"
                :items="results"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
                <template v-slot:cell(result)="row">
                    <span v-if="row.value.none != null"
                        :class="{resultOk: 'text-success', resultDefect: 'text-warning', resultBan: 'text-danger'}[row.value]"
                    >
                        {{ getInspectionResultName(row.value.none) }}
                    </span>
                    <span v-else-if="row.value.number != null">
                        {{ row.value.number }}
                    </span>
                    <span v-else-if="row.value.text != null">
                        {{ row.value.text }}
                    </span>
                    <span v-else-if="row.value.select_single != null">
                        <div class="inline inspectables nopads">
                            {{ row.value.select_single.name }}
                        </div>
                    </span>
                    <span v-else-if="row.value.select_multi != null">
                        <div v-for="selection in row.value.select_multi" :key="selection.id" class="inline inspectables nopads">
                            {{ selection.name }}
                        </div>
                    </span>
                    <span v-else-if="row.value.boolean != null">
                        {{ $t(row.value.boolean ? 'common.yes' : 'common.no') }}
                    </span>
                </template>

                <template v-slot:cell(photos)="row">
                    <div class="col-sm-12 nopads pl-1 p-3 pt-1">
                        <div class="stat-container nopads" style="border: 0;">
                            <ImageView
                                v-for="photo in row.value" :key="photo.id"
                                ref="image"
                                :height="'300px'"
                                :contain="true"
                                :url="getReportPhotoUrl(row.item.id, photo)"
                                @click="openImage(row.item.id, photo)"
                            />
                        </div>
                    </div>
                </template>
            </b-table>
        </div>
        <transition name="fade" >
            <fullscreen-image
                v-if="showPhoto"
                :url="getReportPhotoUrl(this.itemId, this.photo)"
                @close="closePhoto"/>
        </transition>
        <div class="print-only">
            <div>
                <span class="span-header">{{ $t('ins_report_details.signatures') }}</span>
            </div>
            <b-row class="no-pads">
                <div class="col-4 nopads">
                    <div class="signature-line"/>
                    <span>{{ $t('ins_report_details.inspector') }}</span>
                </div>
                <div class="col-4 nopads pl-3">
                    <div class="signature-line"/>
                    <span>{{ $t('ins_report_details.approver') }}</span>
                </div>
            </b-row>
            <b-row>
                <span class="item-detail-text mt-4">{{ $t('common.printed') }} {{ $d(new Date()) }}</span>
            </b-row>
        </div>
    </div>
</template>

<script>
import {vehicleHelper} from '../mixins/VehicleMixin'
import {inspectionHelper} from '../mixins/InspectionMixin'
import {printHelper} from '../mixins/PrintMixin'
import {Printd} from 'printd'
import TargetInfoVehicles from "./TargetInfoVehicles";
import TargetInfoContract from "./TargetInfoContract";
import TargetInfoOrder from "./TargetInfoOrder";
import {timeUtils} from "../mixins/TimeUtils";
import InspectorCertificates from "./InspectorCertificates";
import TargetInfoCompany from "./TargetInfoCompany";
import {restApi} from '../mixins/RestApiMixin'
import FullscreenImage from "../view/FullscreenImage";
import TargetInfoBridge from "@/components/inspections/TargetInfoBridge";
import TargetInfoCulvert from "@/components/inspections/TargetInfoCulvert";
import ImageView from "@/components/view/ImageView.vue";

export default {
    name: 'InspectionReportDetails',
    components: {
        ImageView,
        TargetInfoCulvert,
        TargetInfoBridge,
        FullscreenImage,
        TargetInfoCompany,
        InspectorCertificates,
        TargetInfoOrder,
        TargetInfoContract,
        TargetInfoVehicles
    },
    mixins: [vehicleHelper, inspectionHelper, printHelper, timeUtils, restApi],
    props: {
        reportDetails: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            sortBy: 'inspectable',
            sortDesc: false,
            itemId: null,
            photo: null,
            showPhoto: false,
            fields: [
                {key: 'inspectable', label: this.$t('ins_report_details.inspectable'), sortable: true},
                {key: 'result', label: this.$t('ins_report_details.result'), sortable: true},
                {key: 'info', label: this.$t('ins_report_details.info'), sortable: false},
                {key: 'photos', label: this.$t('ins_report_details.photos'), sortable: false}
            ]
        }
    },

    mounted() {
        this.d = new Printd()
    },

    watch: {
    },

    computed: {
        results() {
            if(this.reportDetails !== undefined) {
                return this.reportDetails.results.map(({id, inspectable, info, photos, number, text, select_single, select_multi, boolean, result: res}) => {
                    let result;
                    if (number != null) {
                        result = { number };
                    }
                    else if (text != null) {
                        result = { text };
                    }
                    else if (select_single != null) {
                        result = { select_single };
                    }
                    else if (select_multi != null) {
                        result = { select_multi };
                    }
                    else if (boolean != null) {
                        result = { boolean };
                    }
                    else {
                        result = { none: res };
                    }
                    return { id, inspectable, result, info, photos }
                });
            }
            else {
                return {}
            }

        }
    },

    methods: {
        print() {
            this.d.print(this.$el, this.printStyle)
        },
        openImage: function (resultId, photo) {
            this.itemId = resultId
            this.photo = photo
            this.showPhoto = true
        },
        closePhoto() {
            this.showPhoto = false
        },
        getReportPhotoUrl: function (resultId, photo) {
            if (photo && photo.id) {
                return this.inspectionResult + resultId + '/photo/' + photo.id + '?inspection_type=' + this.getReportType()
            }
            return null
        },
        getReportType: function () {
            if (this.reportDetails.vehicle) {
                return 1
            } else if (this.reportDetails.contract) {
                return 2
            } else if (this.reportDetails.order) {
                return 3
            } else if (this.reportDetails.inspected_company) {
                return 4
            } else if (this.reportDetails.bridge_id) {
                return 5
            } else if (this.reportDetails.culvert_id) {
                return 6
            }
            return null
        }
    }
}
</script>
