<template>
    <div ref="container" class="content-container">
        <b-navbar variant="faded">
            <div class="header">
                <span class="title">{{ $t('spreader_data.title') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <keep-alive>
                <spreader-data-search
                    @search="fetchSpreaderData"
                />
            </keep-alive>
            <transition v-if="results && results.length > 0" name="fade">
                <spreader-material-charts :results="results"/>
            </transition>
            <spreader-data-list
                :results="results"
                @rowSelected="handleRowSelection"
                @showAll="showAllOnMap"
                @exportToExcel="exportToExcel"
            />
            <!-- Map modal for showing points -->
            <b-modal
                v-model="mapVisible"
                size="xl"
                modal-class="nooverflow"
                body-class="p-0 m-0"
                :ok-title="this.$t('common.close')"
                :ok-only="true"
                ok-variant="primary"
                :hide-header="true"
            >
                <div
                    class="trip-results"
                    style="height:70vh !important;"
                >
                    <!-- Map for showing trip here -->
                    <map-container
                        ref="mapContainer"
                        :find-user="false"
                        @onMapChanged="mapChanged"
                        @onMarkerTap="showPointDetails"
                        @onMultiMarkerTap="onMultiMarkerTap"
                    />
                    <spreader-data-map
                        v-if="map"
                        :spreader-data="details"
                        :spreader-all-data="multipleDetails"
                        :map="map"
                        @close="closeItems"
                    />
                </div>
            </b-modal>
            <b-modal
                ref="selectMarkerModal"
                centered
                hide-footer
                :title="$t('map.select_marker_modal')"
            >
                <map-item-selector
                    v-if="map"
                    :marker-store="map.getMarkerStore()"
                    :markers="markerSelections"
                    @mapItemSelected="showPointDetails"/>
            </b-modal>
            <b-modal
                v-model="harjaSenderVisible"
                size="xl"
                dialog-class="harja-sender-modal"
                modal-class="nooverflow"
                body-class="p-0 m-0"
                :hide-footer="true"
                :title="$t('spreader_data.send_to_harja_title')"
            >
                <spreader-data-harja-sender
                    :user="this.user"
                    :isAdmin="this.isAdmin"
                    :item="spreaderDataToharjaItem"
                    @close="closeHarjaSender"/>
            </b-modal>

           <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import SpreaderDataSearch from "@/components/materials/spreaderdata/SpreaderDataSearch";
import SpreaderDataList from "@/components/materials/spreaderdata/SpreaderDataList";
import SpreaderDataHarjaSender from "@/components/materials/spreaderdata/SpreaderDataHarjaSender";
import {restApi} from "@/components/mixins/RestApiMixin";
import SpreaderMaterialCharts from "@/components/materials/spreaderdata/SpreaderMaterialCharts";
import MapContainer from "@/components/map/MapContainer";
import SpreaderDataMap from "@/components/materials/spreaderdata/SpreaderDataMap";
import {spreaderDataMixin} from "@/components/mixins/SpreaderDataMixin";
import {mapHelper} from "@/components/mixins/MapMixin";
import {timeUtils} from "@/components/mixins/TimeUtils";
import MapItemSelector from "@/components/map/MapItemSelector.vue";
import {EventBus} from "@/event-bus";
import {downloadHelper} from "@/components/mixins/DownloadMixin";

export default {
    name: 'SpreaderData',
    components: {
        MapItemSelector,
        SpreaderDataMap,
        MapContainer,
        SpreaderMaterialCharts,
        SpreaderDataList,
        SpreaderDataSearch,
        SpreaderDataHarjaSender
    },
    mixins: [restApi, spreaderDataMixin, mapHelper, timeUtils, downloadHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
    },
    data: function () {
        return {
            results: [],
            details: null,
            multipleDetails: [],
            markerSelections: [],
            loading: false,
            mapVisible: false,
            harjaSenderVisible: false,
            spreaderDataToharjaItem: {},
            map: null,
            lastSearchedParams: null,
        }
    },
    mounted: function () {
        this.lastSearchedParams = {}
    },
    methods: {
        fetchSpreaderData: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.restFetchParams(this.spreaderDataUrl, this.lastSearchedParams, this.handleResponse)
        },
        handleResponse: function (response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
            }
        },
        handleRowSelection: function (item, openHarjaSender = false) {
            if(openHarjaSender) {
                this.spreaderDataToharjaItem = item
                this.harjaSenderVisible = true
            } else {
                this.showDataOnMap(item)
            }
        },
        showDataOnMap: function (item) {
            this.loading = true
            this.restFetch(this.spreaderDataUrl+"/"+item.id, this.handleDetails)
        },

        handleDetails(response) {
            this.loading = false
            if (response && response.data) {
                this.details = response.data
                this.mapVisible = true
            }
        },
        handleMultipleDetails(response) {
            this.loading = false
            if (response && response.data) {
                this.multipleDetails = response.data
                this.mapVisible = true
            } else if(response && response.status === 500) {
                EventBus.$emit('show-alert', this.$t('trip_list.err_too_much'))
            }
        },
        onMultiMarkerTap(data) {
            let allPointData = this.details ? this.details.point_data : this.multipleDetails.flatMap(item => item.point_data);
            const sources = {
                [this.SPREADER_DATA]: () => allPointData
            };
            const elements = data
                .map(data => {
                    const src = sources[data.type];
                    if (src == null) {
                        return null;
                    }
                    let element = {
                        type: data.type,
                        id: data.id,
                    }

                    element.data = src().find(el => el.id === data.id);
                    return element
                })
                .filter(el => el != null);

            this.$refs.selectMarkerModal.show();
            this.markerSelections = elements;
        },
        closeMarkerModal() {
            this.$refs.selectMarkerModal.hide();
            this.markerSelections = []
        },
        closeItems() {
            this.markerSelections = []
            this.details = null
            this.multipleDetails = []
        },
        closeHarjaSender: function () {
            this.spreaderDataToharjaItem = {}
            this.harjaSenderVisible = false
        },
        mapChanged(map){
            this.$nextTick(() => this.map = map)
        },

        showAllOnMap() {
            if(this.results && this.results.length > 0) {
                let params = this.lastSearchedParams
                params.geometry = 1
                this.loading = true
                this.restFetchParams(this.spreaderDataUrl, params, this.handleMultipleDetails)
            }
        },

        showPointDetails(data) {
            let pointItem;
            let foundItem;
            this.closeMarkerModal()
            if(this.details) {
                pointItem = this.details.point_data.find(item => data.id === item.id)
            } else if(this.multipleDetails && this.multipleDetails.length > 0) {
                for (let item of this.multipleDetails) {
                    // Find the matching pointItem
                    pointItem = item.point_data.find(point => data.id === point.id);

                    if (pointItem) {
                        foundItem = item;
                        break; // Exit the loop once a match is found
                    }
                }
            }
            let bubbleData = []
            bubbleData[this.$t('spreader_data.spreading_width_dry')] = pointItem.spreading_width_dry + ' cm'
            bubbleData[this.getMaterialName(this.details ? this.details.material_dry : foundItem.material_dry)] =
                pointItem.material_dry_amount + ' ' + (this.details ? this.details.material_dry_unit : foundItem.material_dry_unit)
            bubbleData[this.$t('spreader_data.spreading_width_liquid')] = pointItem.spreading_width_liquid + ' cm'
            bubbleData[this.getMaterialName(this.details ? this.details.material_liquid : foundItem.material_liquid)] =
                pointItem.material_liquid_amount + ' ' + (this.details ? this.details.material_liquid_unit : foundItem.material_liquid_unit)
            this.map.showInfoBubble(this.SPREADER_DATA, data.id, this.toLocalTime(pointItem.time), bubbleData, pointItem.position.y, pointItem.position.x )
        },
        exportToExcel() {
            this.loading = true
            this.restGetDownloadFile(this.spreaderDataXlsxUrl, {
                ...this.lastSearchedParams,
                ...this.getCommonReportParams(this.lastSearchedParams)
            }, this.excelDownloadSuccess, this.fileDownloadFail)
        },
        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('spreader_data.report_file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
    }
}
</script>
<style scoped>
    .nooverflow {
        overflow: hidden;
    }
    .harja-sender-modal {
        /** Show Bus event aler banner top of the modal */
        z-index: 4999 !important;
    }
</style>
