<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <div>
            <h3 class="p-3">{{ $t('daily_summary.invoice_history') }}</h3>
        </div>
        <!-- Main table element -->
        <b-table
            hover
            show-empty
            small
            :items="tableItems"
            :fields="fields"
        >
            <template v-slot:cell(invoice_state)="row">
                {{ getStatesString(row.value) }}
            </template>
            <template v-slot:cell(created_date)="row">
                {{ toLocalTime(row.value) }}
            </template>
            <template v-slot:cell(created_by)="row">
                {{ row.value.first_name + " " + row.value.last_name }}
            </template>
        </b-table>
        <div class="col-12 text-right">
            <b-button
                variant="secondary"
                class="form-button"
                @click.stop="$emit('close')"
            >
                {{ $t('common.close') }}
            </b-button>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {invoiceStateHelper} from '@/components/invoice/InvoiceMixin'
import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: 'InvoiceHistories',
    mixins: [timeUtils, workManagementHelper, restApi, invoiceStateHelper],
    components: {},
    props: {
        summaryItem: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            sortBy: 'created',
            sortDesc: true,
            loading: false,
            sortDirection: 'asc',
            results: [],
            tableItems: []
        }
    },
    computed: {
        fields() {
            return [
                {key: 'id', label: this.$t('common.id'), sortable: true},
                {key: 'invoice_state', label: this.$t('daily_summary.invoice_state'), sortable: true},
                {key: 'created_date', label: this.$t('daily_summary.date'), sortable: true},
                {key: 'created_by', label: this.$t('daily_allowance.user'), sortable: true},
            ]
        }
    },
    watch: {
        results() {
            this.results.forEach(result => {
                this.tableItems.push({
                    id: result.id,
                    invoice_state: result.invoice_state,
                    created_date: result.created_date,
                    created_by: result.created_by,
                })
            })
        }
    },
    mounted() {
        this.fetchTripInvoiceHistories()
    },
    methods: {
        acceptInvoicing: function (item) {
            this.$emit('acceptInvoicing', item)
        },
        fetchTripInvoiceHistories: function () {
            if(this.summaryItem) {
                this.loading = true
                this.restFetchParams(this.tripSummaryUrl + '/' + 'invoicehistories', {summaryId: this.summaryItem.id}, this.handleTripInvoiceHistories)
            }
        },
        handleTripInvoiceHistories(response) {
            this.loading = false
            if(response && response.data) {
                this.results = response.data
            }
        }
    }
}
</script>
