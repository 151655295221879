<template>
    <div
        class="details-container"
        @click.stop="close"
    >
        <div
            class="details dynamic_width"
            @click.stop=""
        >
            <div>
                <h3 class="p-3">{{ $t('orders.add_property_value') }}</h3>
            </div>
            <div v-if="properties && Object.keys(properties) && hasValidProperties(properties)" class="col-12">
                <table class="table">
                    <tr v-for="(geometryProperty, index) in Object.keys(formatedProperties)" :key="'geometry-property'+index" class="col-12 nopads pb-2">
                        <td v-if="!excludedFields.includes(geometryProperty)" class="prop-header">{{ geometryProperty }}</td>
                        <td v-if="!excludedFields.includes(geometryProperty)">{{ formatedProperties[geometryProperty] ? formatedProperties[geometryProperty] : '-' }}</td>
                    </tr>
                </table>
            </div>
            <div v-else class="col-12">
                {{ $t('orders.no_properties_found') }}
            </div>
            <div class="button-container">
                <b-button
                    variant="danger"
                    style="margin-top: 5px;"
                    @click.stop="close"
                >
                    {{ $t('common.close') }}
                </b-button>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>

import {restApi} from "@/components/mixins/RestApiMixin";

export default {
    name: "OrderGeometryPropertyModal",
    components: {},
    mixins: [restApi],
    props: {
        properties: {
            type: Object,
            default: null
        },
        excludedFields: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        formatedProperties() {
            const processObject = (obj) => {
                return Object.keys(obj).reduce((acc, key) => {
                    const value = obj[key];
                    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
                        Object.assign(acc, processObject(value));
                    } else if (value !== null && value !== '') {
                        acc[key] = value;
                    }
                    return acc;
                }, {});
            };
            return processObject(this.properties);
        },
    },

    mounted() {
        const orderId = this.properties.order_id
        this.fetchOrderInfo(orderId)
    },

    methods: {
        fetchOrderInfo(id) {
            const url = this.orderUrl+'/'+id
            this.loading = true
            this.restFetch(url, this.handleResponse)
        },
        handleResponse(response) {
            this.loading = false
            const order = response.data
            const properties = JSON.parse(JSON.stringify(this.properties));
            properties.order_type = order.contract_task_type.task_type.name
            properties.order_info = order.info
            this.$emit('setproperties', properties)
        },
        hasValidProperties(properties) {
            for (const key in properties) {
                if (!this.excludedFields.includes(key)) {
                    return true;
                }
            }
            return false;
        },
        close() {
            this.$emit("close")
        },
    }
}
</script>
<style>
.prop-header {
    font-weight: 800;
    font-size: 0.8em;
    text-transform: uppercase;
}
</style>
