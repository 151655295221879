<template>
    <div ref="contentContainer" class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div class="header">
        <span class="title">
          {{ $t('daily_summary.title') }}</span>
            </div>
        </b-navbar>
        <contract-trip-summary-filter
            :user="user"
            :invoice-state-options="invoiceStateOptions"
            :pre-selected-contract="preSelectedContract"
            @search="fetchDailySummaries"
        />
        <div class="header col-sm-12 button-container pt-1">
            <font-awesome-icon
                v-if="searchParams"
                icon="print"
                class="print-icon"
                @click.stop="print"
            />
        </div>
        <div id="printContainer" class="report" v-if="searchParams">
            <!-- Basic info -->
            <div class="pt-2 pb-3">
                <span class="title nopads report-heading">{{ $t('daily_summary.title') }}</span>
                <span
                    class="title nopads">{{
                        getShortDateWithoutTimezone(searchParams.from)
                    }} - {{ getShortDateWithoutTimezone(searchParams.to) }} </span>
            </div>
            <table class="table table-sm">
                <tbody>
                <tr v-if="contractNames">
                    <td class="item-detail-title">
                        {{ searchParams && searchParams.contractIds && searchParams.contractIds.length > 1 ? $t('contract_reports.contracts') : $t('contract_reports.contract') }}
                    </td>
                    <td class="item-detail-text">
                        {{ contractNames }}
                    </td>
                </tr>
                <tr v-if="customerName">
                    <td class="item-detail-title">
                        {{ $t('contract_reports.customer') }}
                    </td>
                    <td class="item-detail-text">
                        {{ customerName }}
                    </td>
                </tr>
                <tr v-if="executorName">
                    <td class="item-detail-title">
                        {{ $t('contract_reports.contractor') }}
                    </td>
                    <td class="item-detail-text">
                        {{ executorName }}
                    </td>
                </tr>
                <tr v-if="orderNames">
                    <td class="item-detail-title">
                        {{ searchParams && searchParams.orders && searchParams.orders.length > 1 ? $t('contract_reports.orders') : $t('contract_reports.order') }}
                    </td>
                    <td class="item-detail-text">
                        {{ orderNames }}
                    </td>
                </tr>
                <tr v-if="areaGroup">
                    <td class="item-detail-title">
                        {{ $t('contracts.area_group') }}
                    </td>
                    <td class="item-detail-text">
                        {{ areaGroup }}
                    </td>
                </tr>
                <tr v-else-if="searchParams.contract">
                    <td class="item-detail-title">
                        {{ $t('contracts.area_group') }}
                    </td>
                    <td class="item-detail-text">
                        {{ $t('daily_summary.no_area') }}
                    </td>
                </tr>
                </tbody>
            </table>
            <hr class="report-divider nopads"/>
            <div v-if="summaries.length">
                <div class="button-container">
                    <b-button
                        variant="outline-primary"
                        class="result-button dont-print"
                        @click="showCharts = !showCharts"
                    >
                        {{ showCharts ? $t('daily_summary.hide_graphs') : $t('daily_summary.show_graphs') }}
                    </b-button>
                </div>
                <contract-trip-summary-charts
                    v-show="showCharts"
                    ref="charts"
                    :results="summaries"
                />
                <!-- Trip table -->
                <hr class="report-divider"/>
                <contract-trip-summary-list
                    :summaries="summaries"
                    :params="searchParams"
                    :user="user"
                    :invoice-state-options="invoiceStateOptions"
                    @rowSelected="showTripDetails"
                    @deliveryTonsClicked="showTripDeliveries"
                    @invoiceHistoryClicked="showInvoiceHistories"
                    @contractClicked="showContractDetails"
                    @orderClicked="showOrder"
                    @loading="handleLoading"
                    @updateRequest="updateData"
                />
                <hr class="report-divider nopads"/>
            </div>
            <!-- Material reports -->
            <material-report
                :params="searchParams"
            />
            <!-- Sport track work list -->
            <sport-work-list
                :params="searchParams"
                @rowSelected="showSkiWorks"
            />
            <div class="row print-only">
                <span class="item-detail-text">{{ $t('common.printed') }} {{ $d(new Date()) }}</span>
            </div>
        </div>
        <sport-work-details
            v-if="showSkiWorkDetails && skiWorkDetailsRow"
            @close="showSkiWorkDetails = false"
            :sportWorkItem="skiWorkDetailsRow"
        />
        <div v-if="showHistories && invoiceHistoryRow" class="details-container">
            <div class="details dynamic_width">
                <invoice-histories
                    v-if="showHistories && invoiceHistoryRow"
                    @close="showHistories = false; invoiceHistoryRow = null"
                    :summaryItem="invoiceHistoryRow"
                />
            </div>
        </div>
        <contract-trip-details
            v-if="tripDetailsRow && showDetails"
            @close="showDetails = false"
            :order-item="order"
            :summaryItem="tripDetailsRow"
            :tripTable="trips"
        />
        <contract-trip-deliveries
            v-if="tripDetailsRow && showDeliveries"
            @close="showDeliveries = false"
            :summary-item="tripDetailsRow"
        />
        <div v-if="showContract" class="details-container">
            <div class="details dynamic_width">
                <contract-editor
                    v-if="tripDetailsRow && showContract"
                    v-model="contract"
                    :active-order-editor="activeOrderEditor"
                    :contract-trip-list="true"
                    :user="user"
                    :show-title="false"
                    @close="showContract = false"
                />
              </div>
        </div>
        <div v-if="showOrderDetails" class="details-container">
            <div class="details dynamic_width">
                <order-editor
                    v-if="tripDetailsRow && showOrderDetails"
                    @close="closeOrder"
                    @closeAndUpdate="closeOrder"
                    :order="order"
                    :contract="contract"
                    :user="user"
                    :show-title="false"
                />
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {invoiceStates, invoiceStateHelper} from "@/components/invoice/InvoiceMixin";
import InvoiceHistories from "@/components/invoice/InvoiceHistories";
import {EventBus} from '@/event-bus'
import ContractTripSummaryCharts from './ContractTripSummaryCharts'
import {printHelper} from '../mixins/PrintMixin'
import {vehicleHelper} from '../mixins/VehicleMixin'
import {Printd} from 'printd'
import ContractTripSummaryFilter from './ContractTripSummaryFilter'
import ContractTripSummaryList from './ContractTripSummaryList'
import MaterialReport from "../workreport/MaterialReportDetails"
import ContractTripDetails from '../tripsummary/ContractTripDetails';
import ContractTripDeliveries from "@/components/tripsummary/ContractTripDeliveries";
import {workManagementHelper} from "../mixins/WorkManagementMixin";
import OrderEditor from "../order/OrderEditor";
import SportWorkList from "@/components/sports/sportwork/SportWorkList";
import SportWorkDetails from "@/components/sports/sportwork/SportWorkDetails";
import ContractEditor from "@/components/contract/ContractEditor";

export default {
    name: 'contract-trip-summaries',
    components: {
        ContractEditor,
        SportWorkList,
        SportWorkDetails,
        InvoiceHistories,
        OrderEditor,
        ContractTripDeliveries,
        MaterialReport, ContractTripSummaryFilter, ContractTripSummaryCharts, ContractTripSummaryList, ContractTripDetails},
    mixins: [restApi, timeUtils, printHelper, vehicleHelper, workManagementHelper, invoiceStates, invoiceStateHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        preSelectedContract: {
            type: Number,
            default: null
        }
    },
    data: function () {
        return {
            summaries: [],
            loading: false,
            searchParams: null,
            noResults: false,
            contractNames: '',
            orderNames: '',
            contract: null,
            tripDetailsRow: null,
            skiWorkDetailsRow: null,
            invoiceHistoryRow: null,
            showHistories: false,
            tripSummaryParams: {},
            trips: [],
            invoiceStateOptions: [],
            areaGroup: null,
            executorName: null,
            showDetails: false,
            showSkiWorkDetails: false,
            showContract: false,
            showCharts: true,
            activeOrderEditor: false,
            order: null,
            showOrderDetails: false,
            showDeliveries: false
        }
    },
    mounted() {
        this.d = new Printd()
        this.setInvoiceStates()
    },
    methods: {
        checkActiveOrder: function(active){
            this.activeOrderEditor = active
        },
        setInvoiceStates() {
            let invoiceStates = this.getInvoiceStates()
            this.invoiceStateOptions = [...invoiceStates]
        },
        updateData: function () {
            this.fetchDailySummaries(this.searchParams, this.customerName, this.contractName, this.executorName,
                this.areaGroup, this.contractNames, this.orderNames)
        },
        fetchContracts: function(params, showContract = true) {
            this.loading = true
            this.restFetchParams(this.contractUrl, params, response => this.handleContracts(response, showContract), this.notAuthorized)
        },
        handleContracts: function (response, showContract = true) {
            let fetchedContract = response ? response.data[0] : {}
            this.loading = false
            if (fetchedContract && fetchedContract.is_contract_party) {
                this.contract = this.jsonToItem(fetchedContract)
                if (showContract) {
                    this.showContract = true
                }
            }
        },

        notAuthorized: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('daily_summary.contract_access_error'))
        },

        fetchDailySummaries: function (params, customerName, executorName, areaName, contractNames, orderNames) {
            this.loading = true
            this.noResults = false
            this.searchParams = params
            this.customerName = customerName
            this.areaGroup = areaName
            this.executorName = executorName
            this.contractNames = contractNames
            this.orderNames = orderNames
            this.restFetchParams(this.contractDailyTripSummaryUrl, this.searchParams, this.handleResponse, this.fail)
        },

        fetchOrders: function (params, showDetailsEditor) {
            this.handleOrders(params, showDetailsEditor);
        },

        handleOrders: function(params, showDetailsEditor) {
            this.loading = true;
            this.restFetch(this.orderUrl + "/" + params.id, (response) => {
                let fetchedOrder = response ? response.data : {};
                this.order = this.createEditorOrder(fetchedOrder);
                this.loading = false;
                if (showDetailsEditor) {
                    this.showOrderDetails = true;
                }
            }, this.orderFail);
        },

        handleResponse: function (response) {
            this.loading = false
            this.summaries = response.data
            if (this.summaries.length < 1) {
                this.noResults = true
            }
        },
        fail: function () {
            this.loading = false
            this.summaries = []
            EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
        },
        orderFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
        },
        print: function () {
            if (!this.loading) {
                if (!this.noResults) {
                    this.$refs.charts.generateImages()
                }
                this.d.print(document.getElementById('printContainer'), this.printStyle)
            }
        },
        handleLoading: function (loading) {
            this.loading = loading
        },
        showTripDetails: function (selectedRow){
            this.tripDetailsRow = selectedRow
            this.showDetails = true
            this.showOrder(selectedRow, false)
        },
        showSkiWorks: function (selectedRow){
            this.skiWorkDetailsRow = selectedRow
            this.showSkiWorkDetails = true
        },
        showTripDeliveries: function (selectedRow){
            this.tripDetailsRow = selectedRow
            this.showDeliveries = true
        },
        showContractDetails: function (selectedRow){
            this.tripDetailsRow = selectedRow
            let params = {}
            params.id = selectedRow.contract_id ? selectedRow.contract_id : null
            this.fetchContracts(params)
        },
        showInvoiceHistories(selectedRow) {
            this.invoiceHistoryRow = selectedRow
            this.showHistories = true
        },
        scrollTop: function () {
            this.$nextTick(function () {
                this.$refs.contentContainer.scrollTop = 0
            })
        },
        closeAndUpdate: function () {
            if(this.activeOrderEditor){
                this.showContract = true
                this.activeOrderEditor = false
            }else{
                this.contract = null
                this.showContract = false
            }
        },
        closeOrder: function() {
            this.showOrderDetails = false
            this.order = null
        },
        showOrder: function (selectedRow, showDetailsEditor = true){
            this.tripDetailsRow = selectedRow
            let params = {}
            params.id = selectedRow.order.id ? selectedRow.order.id : null

            let oParams = {}
            oParams.id = selectedRow.contract_id ? selectedRow.contract_id : null

            this.contract = null
            this.fetchContracts(oParams, false)
            this.fetchOrders(params, showDetailsEditor)
        },
        handleError: function () {
            EventBus.$emit('show-alert', this.$t('trip_list.err_general'))
        },
    }
}
</script>
